import React from 'react';
import urljoin from 'url-join'
import FirebaseContext from 'auth/FirebaseContext';
import { Link } from 'react-router-dom'

import ReactTooltip from 'react-tooltip'
import NextArrow from './subcomponents/NextArrow';
import RectangleButton from '../../ui_elements/rectangle/RectangleButton'

import Info from 'img/info.png'
import Arrow from 'img/arrow.png'

import LoadingMessage from '../../misc_components/LoadingMessage';

export default class GroupSelect extends React.Component {
  static contextType = FirebaseContext;

  constructor(props) {
    super(props);
    this.state = {
      groups: null
    }
  }

  async componentDidMount() {
    const roleUser = this.context.rolesData;
    // TODO: Handle them not having a favorite_location_id
      var response = await this.context.imagarenaApiClient.get(urljoin(process.env.REACT_APP_GROUP_MANAGER_SVC_ENDPOINT, 'locations', roleUser.favorite_location_id, 'groups')); 
      const groups = response.data.groups;
      this.setState({ groups });
  }

  render() {
    if (this.state.error) return (
      <div>
        <h2>An error occured while attempting to load this page.</h2>
        <h4>Make sure you are connected to the internet, then click the 'RETRY' button.  If that doesn't work, it may be a problem with your organization's firewall.  See the 'support' page for more information. </h4>
        <button>RETRY</button>
        
        <h2>Details:</h2>
        <h3>URL: {this.state.error.config.url}</h3>
        <h3>Status Code: {this.state.error.status}</h3>
        <h3>Status Message: {this.state.error.statusText}</h3>
      </div>
    )

    if (this.state.networkError) return <h4>Make sure you are connected to the internet, then click the 'RETRY' button.  If that doesn't work, it may be a problem with your organization's firewall.  See the 'support' page for more information. </h4>
    if (!this.state.groups) return <LoadingMessage type='default' message='Loading groups...' />

    const tipText = "Choose a Group for this session. If you’d like to add a new Group, head over to the Manager."

    const allGroups = this.state.groups.map(group => {
      const selected = this.props.selectedGroup && this.props.selectedGroup._id === group._id;
      return <RectangleButton
        key={`${group._id}-${selected}`}
        id={`${group._id}-${selected}`}
        size={'sm'}
        text={group.name}
        selected={selected}
        selectCallback={() => this.props.selectGroup(group)}
      />
    });

    const noGroupsMessage = (
      <div className="no-groups-message">
        <h3>There are no groups yet for this location.</h3>
        <Link to={'/groupmanager'}>
          <RectangleButton text={'Go to the Group Manager'} img={Arrow} color={'green'} size={'md'} />
        </Link>
      </div>
    );

    return (
      <div className="launcher-container">
        <div className="title-section">
          <ReactTooltip id="tip" className="tip">{tipText}</ReactTooltip>
          <div className="title">Select Group </div>
          <img src={Info} data-tip data-for="tip"/>
        </div>
            
        {this.props.valid ? <NextArrow goNext={this.props.goNext} /> : ''}

        <div className="group-select">
          { allGroups }
          { !this.state.groups.length ? noGroupsMessage : '' }
        </div>
      </div>
    )
  }
}
