import React from 'react'
import FirebaseContext from 'auth/FirebaseContext';

import PlayerOverlay from '../overlay/PlayerOverlay'
import OverlayAnimations from '../overlay/anime/OverlayAnimations'

import './css/player.css'
import 'otaku/src/otaku.css'
import '../../animations/text_hack.css'

import InputHandler from '../classes/InputHandler';

export default class Player extends React.Component {
  static contextType = FirebaseContext;
  constructor(props) {
    super(props);

    this.sequence = this.props.sequence;

    this.state = {
      currentSlide: this.sequence.getCurrentSlide(),
      isPaused: false,
      loadingAssets: false,
      nextSlideEnabled: false,
      
      inspectorVisible: false,
      gridVisible: false,
      disableSlideWait: false
    }

    this.usedPlaythroughIds = [];
    this.usedDefaultPhotoIds = [];
    this.overlayAnimations = new OverlayAnimations();

    this.handleNextSlideEvent = this.handleNextSlideEvent.bind(this);
    this.togglePause = this.togglePause.bind(this);
    this.handlePreviousSlideEvent = this.handlePreviousSlideEvent.bind(this);
    this.toggleInspector = this.toggleInspector.bind(this);
    this.enableNextSlide = this.enableNextSlide.bind(this);
  }

  async componentDidMount() {
    this.registerEvents();
    this.overlayAnimations.setElements();
  }

  registerEvents() {
    this.registerKeyboardEvents();
  }

  registerKeyboardEvents() {
    const nextHandler = new InputHandler(['ArrowRight','ArrowUp', 'PageUp'], this.handleNextSlideEvent);  
    const pauseHandler = new InputHandler(['ArrowDown', 'PageDown'], this.togglePause);
    const previousHandler = new InputHandler(['ArrowLeft'], this.handlePreviousSlideEvent);
    const showSequenceInspectorHandler = new InputHandler(['KeyS'], this.toggleInspector);

    const allHandlers = [nextHandler, pauseHandler, previousHandler];

    if (process.env.REACT_APP_ENV_NAME === 'dev') {
      allHandlers.push(showSequenceInspectorHandler);
    }

    window.addEventListener('keydown', (event) => {
      for(let i = 0; i < allHandlers.length; i++) { allHandlers[i].listenForKeyDown(event.code)}
    });

    window.addEventListener('keyup', (event) => {
      for(let i = 0; i < allHandlers.length; i++) {allHandlers[i].listenForKeyUp(event.code)}
    });
  }

  handleNextSlideEvent() {
    if (process.env.REACT_APP_ENV_NAME === 'dev' && this.state.disableSlideWait) {
      this.goToNextSlideImmediately();
      return;
    }

    if (this.sequence.index === this.sequence.slides.length - 1) {
      this.overlayAnimations.showCompleteMessage();
      return;
    }

    if (this.state.loadingAssets) {
      this.overlayAnimations.showLoadingMessage();
      return;
    }

    if (!this.state.nextSlideEnabled) {
      this.overlayAnimations.showWaitMessage();
      return;
    }

    this.goToNextSlide();
  }

  async handlePreviousSlideEvent() {
    if (!this.sequence.index) return;
    
    await this.loadUpcomingSlide(this.sequence.getPreviousSlide());
    this.sequence.decrement();
    this.showCurrentSlide();
    this.setState({ nextSlideEnabled: false });

    console.info('previous slide event')
  }

  async enableNextSlide() {
    this.setState({ loadingAssets: true }, () => this.overlayAnimations.showLoading());
    await this.loadUpcomingSlide(this.sequence.getNextSlide());
    this.setState({ loadingAssets: false, nextSlideEnabled: true }, () => this.overlayAnimations.showReady());
  }

  goToNextSlide() {
    this.sequence.increment();
    this.showCurrentSlide();
    this.setState({ nextSlideEnabled: false });
  }

  async showCurrentSlide(slide) {
    this.setState({
      currentSlide: this.sequence.getCurrentSlide(),
      isPaused: false
    }, () => this.overlayAnimations.hideAll());
  }

  async loadUpcomingSlide(upcomingSlide) {
    await upcomingSlide.loadAssets(this.props.playthrough, this.usedPlaythroughIds, this.usedDefaultPhotoIds, this.context.imagarenaApiClient);
  }

  togglePause() {
    this.setState(prevState => ({
      isPaused: !prevState.isPaused
    }), () => this.state.isPaused ? this.pauseAnimation() : this.resumeAnimation());
    console.info('player paused')
  }

  pauseAnimation() {
    this.overlayAnimations.showPause();
    this.refs.activeAnimation.pause();
  }

  resumeAnimation() {
    this.overlayAnimations.hidePause();
    this.refs.activeAnimation.resume();
  }

  showActiveIndicator() {
    this.overlayAnimations.showActiveIndicator()
    console.info('player window activated')
  }
  
  // 1/3/20 CHECK: for inspector only...
  async goToNextSlideImmediately() {
    try {
      await this.loadUpcomingSlide(this.sequence.getNextSlide());
      this.goToNextSlide();
    } catch(err) {
      console.log('An error occured on loadUpcomingSlide() that crashed the sequence.')
      console.log(err)
      this.togglePause()
    }
  }

  // 1/3/20 CHECK: for inspector only...
  async skipToEnd() {
    this.sequence.skipToEnd();
    this.showCurrentSlide();
    this.setState({ nextSlideEnabled: false });
  }

  // 1/5/20 CHECK: for inspector only...
  toggleInspector() {
    this.setState(prevState => ({ inspectorVisible: !prevState.inspectorVisible }));
  }

  render() {
    const inspectorGridButton = <button onClick={() => this.setState(prevState => ({ gridVisible: !prevState.gridVisible }))}>Toggle Grid</button>;
    const inspectorDisableButton = <button onClick={() => this.setState(prevState => ({ disableSlideWait: !prevState.disableSlideWait }))}>Disable Slide Wait</button>;
    const inspectorskipToEnd = <button onClick={() => this.skipToEnd()}>Skip To End</button>;

    const inspector = (
      <div id="inspector-container">
        <h4>Sequence Inspector</h4>
        <h5>{ this.state.currentSlide.constructor.name }</h5>
        <h5>{this.sequence.index + 1} / { this.sequence.slides.length }</h5>
        { this.state.disableSlideWait ? <h5>SLIDE WAIT DISABLED</h5> : '' }
        { inspectorDisableButton }
        { inspectorGridButton }
        { inspectorskipToEnd }
      </div>
    );

    const currentSlideProps = {
      ref: "activeAnimation",
      enableNextSlide: this.enableNextSlide
    }

    const animationComponentWithRefAndProps = React.cloneElement(this.state.currentSlide.getComponent(), currentSlideProps);

    return (
      <div>
        { this.state.inspectorVisible ? inspector  : '' }
        <div id="player-background" onClick={() => this.showActiveIndicator()}>
          { animationComponentWithRefAndProps }
          <PlayerOverlay gridVisible={this.state.gridVisible} />
        </div>
      </div>
    );
  }
}
