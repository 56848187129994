import ImageChild from './ImageChild'

export default class TextChild extends ImageChild {
    constructor(options) {
        super(options);
        this.color = options.color !== undefined ? options.color : 'red';
        this.font = options.font !== undefined ? options.font : 'sans-serif';
        this.fontWeight = options.fontWeight;
    }

    getStartingPosition() {
        return  {
            width: `${this.width()}%`,
            height: `${this.height()}%`,
            translateX: this.setX(this.x),
            translateY: this.setY(this.y),
            scale: this.scale,
            opacity: this.opacity,
            rotateZ: this.rotation,
            color: this.color,
            fontFamily: this.font,
            fontWeight: this.fontWeight
        }
    }
}