import { Howl } from 'howler';

export default class AudioPlayer {
    constructor() {
        this.index = 0;
        this.activeClips = {}
    }

    pause() {
        this.pauseAllClips();
        this.pauseLoop();    
    }

    resume() {
        this.resumeAllClips();
        this.resumeLoop();
    }

    killAllAudio() {
        if (this.loopingAudio) {
            this.loopingAudio.pause();
            this.loopingAudio = null;
        }

        if (this.autoplayer) {
            this.autoplayer.pause();
            this.autoplayer = null;
        }

        Object.values(this.activeClips).forEach((clip) => {
            clip.pause();
            clip = null;
        });
    }   

    // TODO: @sh0shinsha setInterval for delayed clip plays ignores the pauseAllClips message...
    pauseAllClips() {
        Object.values(this.activeClips).forEach((clip) => {
            clip.pause();
        });
    }
    resumeAllClips() {
        Object.values(this.activeClips).forEach((clip) => {
            clip.play();
        });
    }
    pauseLoop() {
        if (this.loopingAudio) this.loopingAudio.pause();
    }
    resumeLoop() {
        if (this.loopingAudio) this.loopingAudio.play();
    }
    
    configureClip(clip) {
        this.activeClips[clip._src] = clip;
        this.activeClips[clip._src].on('end', () => {
            delete this.activeClips[clip._src];
        });

        return clip;
    } 

    playClip(...source) {
        const clips = source.map(x => new Howl(x));

        for (let i = 1; i < clips.length; i++) {
            clips[i - 1].on('end', () => {
                const clip = this.configureClip(clips[i]);
                clip.play();
            });
        }

        const firstClip = this.configureClip(clips[0]);
        firstClip.play();
    }

    playLoop(track) {
        this.loopingAudio = new Howl(track);
        this.loopingAudio.play();
        this.loopingAudio.fade(0, 0.15, 3000); // overrides the audioMixer volume
        this.loopingAudio.loop(true);
    }
}
