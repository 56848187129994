import React from 'react';
import { Link } from 'react-router-dom'

import RectangleButton from '../ui_elements/rectangle/RectangleButton'
import audio from '../ui_elements/ui-audio.json'

import BetaLogo from '../img/imagarena_text_logo.png'
import DevLogo from '../img/imagarena_text_logo_dev.png'

export default class NextArrow extends React.Component {
  constructor(props) {
    super(props);
    this.audio = audio;
    this.state = {
      text: 'Click an option below to get started…' 
    }
  }

  showText(tag) {
    if (tag === 'launcher') {
      this.setState({ text: 'Click here to enter the Level Launcher...'});
    }

    if (tag === 'manager') {
      this.setState({ text: 'Click here to enter the Group Manager...'});
    }
  }

  resetText() {
    this.setState({ text: 'Select an option below to get started...' });
  }

  render() {

    const logo = process.env.REACT_APP_ENV_NAME === 'beta' ? BetaLogo : DevLogo;

    return (
        <div id="home-container">
          <img id="home-screen-logo" src={logo} />

          <h3 id="home-selection-text">{ this.state.text }</h3>

          <div id="home-buttons">
              <Link to={'/launcher'} id="launcher" onMouseEnter={() => this.showText('launcher')} onMouseLeave={() => this.resetText()}><RectangleButton text={'Launcher'} size={'md'} /></Link>
              <Link to={'/groupmanager'} id="manager" onMouseEnter={() => this.showText('manager')} onMouseLeave={() => this.resetText()}><RectangleButton text={'Manager'} size={'md'} /></Link>
          </div>
        </div>
    );
  }
}
