import React from 'react';
import { Link } from 'react-router-dom'

import RectangleButton from '../../ui_elements/rectangle/RectangleButton'

import Eyeball from 'img/show.png'

export default class GroupSelector extends React.Component {
  constructor(props) {
    super(props);

    this.groupObject = this.props.groupObject;
  }

  async deleteGroup() {
    if (window.confirm(`Are you sure you want to delete this group?  All of its history and photos will be lost.`)) {
      if (window.confirm(`Confirm deletion of ${this.groupObject.name}`)) {
        await this.groupObject.delete();
        this.props.refresh();
      }
    }
  }

  render () {
    return (
      <div className="group-selector">
          <div className="group-attribute">{this.groupObject.name}</div>
          <div className="group-buttons">
            <Link to={`/playthroughs/${this.groupObject._id}`}><RectangleButton text={'VIEW'} size={'sm'} img={Eyeball} reverse={true} /></Link>
            <RectangleButton size={'xs'} text={'EDIT'} selectCallback={() => this.props.edit()} />
            {/* <RectangleButton size={'xs'} clickSFX={'negative'} text={'DELETE'} selectCallback={() => this.deleteGroup()} /> */}
          </div>

      </div>
    )
  }
}
