import React from 'react';

import './player-overlay.css'

import ReadyIcon from './assets/ready.png'
import LoadingIcon from './assets/loading.gif'
import LoadingMessage from './assets/loading_message.png'
import PauseIcon from './assets/pause.png'
import SkipIcon from './assets/skip.png'
import LevelCompleteIcon from './assets/level_complete.png'
import ActiveIndicator from './assets/active_indicator.png'

import OtakuGrid from '../../otaku/examples/test_assets/grid.png'

export default function PlayerOverlay(props) {
  return (
    <div id="screen-overlay" className="parent">
      <img id="ready-gif" className="child" src={ReadyIcon} />
      <img id="loading-img" className="child" src={LoadingIcon} />
      <img id="pause-img" className="child canvas" src={PauseIcon} />
      <img id="skip-img" className="child" src={SkipIcon} />
      <img id="loading-message-img" className="child" src={LoadingMessage} />
      <img id="complete-img" className="child" src={LevelCompleteIcon} />
      <img id="active-indicator" className="canvas" src={ActiveIndicator} />
      { props.gridVisible ?  <img id="grid" src={OtakuGrid} /> : '' }
    </div>
  )
}