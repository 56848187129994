import { Howl } from 'howler';

export default class MusicPlaylist {
    constructor(tracks) {
        this.tracks = tracks;
    }

    autoplay(i, list) {
        this.activeTrack = new Howl({
            src: [list[i].src[0]],
            volume: [list[i].volume],
            preload: true,
            onend: () => {
                if ((i + 1) == list.length) {
                    this.autoplay(0, list)
                } else {
                    this.autoplay(i + 1, list)
                }
            }
        })
        this.activeTrack.play();
    }

    startPlaylist() {
        this.autoplay(0, this.tracks);
    }

    pausePlaylist() {
        this.activeTrack.pause();
    }

    resumePlaylist() {
        this.activeTrack.play();
    }

    stopPlaylist() {
        this.activeTrack.pause();
        this.activeTracks = null;
    }

}