import React from 'react';

import MapCell from './sub_components/MapCell'
import MapTimeline from '../scripts/anime/MapTimeline'

export default class MapAnimation extends React.Component {
    constructor(props) {
        super(props);
        this.animation = new MapTimeline({
            enableNextSlide: this.props.enableNextSlide,
            isPreview: this.props.flags.isPreview,
            roundNum: this.props.flags.roundNumber,

            staticAudio: this.props.staticAudio,
            dynamicAudio: this.props.dynamicAudio
        })
    }

    componentDidMount() {
        this.animation.animate(document.getElementById("vid"));
    }

    componentWillUnmount () {
        this.animation.tearDown();
    }

    pause() {
        this.animation.pause(document.getElementById("vid"));
    }

    resume() {
        this.animation.resume(document.getElementById("vid"));
    }

    render() {

        const blackBackground = <div id="black-background" className="child" />;

        // QUESTION: should this go in staticImages?
        const completeVideo = <video id="vid" className="child canvas" src={this.props.staticImages.completeVideo} type="video/mp4" />;

        const whiteBackground = <div id="white-background" className="child" />;

        const mapCells = this.props.flags.mapCells.map((round, i) => {
            return <MapCell
                key={i}
                cellId={round.round}
                roundNum={this.props.flags.roundNumber}
                isJumbo={round.isJumbo}
                isNewMode={round.isNewMode}
                types={round.types}
                colors={round.colors}    
                special={round.special}
                isFill={round.isFill}

                staticImages={round.staticImages}
                dynamicImages={round.dynamicImages}
            /> 
        });

        const mapFrame = <img id="map-frame-canvas" className="child canvas" src={this.props.staticImages.mapFrame} />;

        const mapContainer = (
            <div id="map-container" className="parent container">
                { whiteBackground }
                { mapCells }
                { mapFrame }
            </div>
        );

        const idleGraphic = <img id="idle-graphic" className="child" src={this.props.flags.roundNumber === 8 ? this.props.staticImages.cameraWhite : this.props.staticImages.play} />;

        

    return (
        <div id="screen" className="parent">

            
            {/* { this.props.isPreview ? '' : blackBackground } */}
            {/* waiting on the video to load is what's causing the black bg glitch */}

            { completeVideo }

            { blackBackground }

            { idleGraphic }
        
            { mapContainer }

            

        </div>
    )
  }
}
