import React from 'react'
import urljoin from 'url-join'
import FirebaseContext from 'auth/FirebaseContext';

import GeneralEditor from '../../misc_components/GeneralEditor'

import RectangleButton from '../../ui_elements/rectangle/RectangleButton'

function LocationSelector(props) {
  return (
    <div key={props._id} className="all-user-container">
        <div className="user-subsection">
          <h2>{props.location.name}</h2>
        </div>
        <RectangleButton size={'xs'} text={'EDIT'} selectCallback={() => props.edit()} color={'blue'} />
    </div>
  )
}

export default class Locations extends React.Component {  
  static contextType = FirebaseContext;

  constructor(props) {
      super(props);
      this.state = {
        locations: null,
        newLocation: { name: "" },
        creatorVisible: false,
        exceededLength: false,
        editingGroup: null,
      }
  }

  setNewLocationName(event) {
    if (event.target.value.length > 35 ) {
      this.setState({ exceededLength: true });
      event.target.value.slice(0, 35);
      return;
    }

    this.setState({ exceededLength: false });
    this.setState({newLocation: {name: event.target.value}});
  }

  async createGroup() {
    if (!this.state.newLocation.name) {
      alert('Please enter a group name.');
      return;
    }
    const data = {
      location: {
        name: this.state.newLocation.name
      }
    }

    const response = await this.context.imagarenaApiClient.post(urljoin(process.env.REACT_APP_GROUP_MANAGER_SVC_ENDPOINT, 'locations/'), data);

    this.setState({ exceededLength: false })
    this.clearnewLocation();
    this.props.refresh();
    this.hideCreator();
  }

  selectEditingGroup(editingGroup) {
    if (this.state.creatorVisible) {
      alert('Please finish creating a new group.');
      return;
    }

    this.setState({editingGroup})
  }

  showCreator() {
    if (this.state.editingGroup) {
      alert('Please finish editing before creating a new group.');
      return;
    }

    this.setState({ creatorVisible: true });
  }

  hideCreator() {
    this.setState({ creatorVisible: false, exceededLength: false });
    this.clearnewLocation();
  }

  clearnewLocation() {
    this.setState({ newLocation: {name: ""} }); // clear the buffer
  }

  clearEditingGroup() {
    this.setState({ editingGroup: null, exceededLength: false });
  }
  

  render() {
    const groupCreator = (
      <div>
        <div className="">
          <h5>Location Name:</h5>
          <input value={this.state.newLocation.name} onChange={(event) => this.setNewLocationName(event)} />
          <div className="user-form-buttons">
            <RectangleButton size={'md'} clickSFX={'positive'} text={'CONFIRM'} color={'green'} selectCallback={() => this.createGroup()}/>
            <RectangleButton size={'sm'} clickSFX={'negative'} text={'CANCEL'} color={'red'} selectCallback={() => this.hideCreator()}/>
          </div>
        </div>
        { this.state.exceededLength ? <h5 className="character-warning">Group Names are limited to 35 characters</h5> : '' }
      </div>
    );
    
    return (
      <div>
  
        { this.state.creatorVisible ? groupCreator : <RectangleButton size={'sm'} color={'green'} text={'+ CREATE NEW LOCATION'} selectCallback={this.state.creatorVisible ? () => this.hideCreator() : () => this.showCreator()} /> }

        { this.state.editingGroup ? <GeneralEditor url={urljoin(process.env.REACT_APP_GROUP_MANAGER_SVC_ENDPOINT, 'locations', this.state.editingGroup._id)} refresh={() => this.props.refresh()} group={this.state.editingGroup} key={this.state.editingGroup.id} clearEditingGroup={() => this.clearEditingGroup()}/> : '' }

        { this.state.creatorVisible ? '' : this.props.locations.map(location => <LocationSelector key={location._id} location={location} edit={() => this.selectEditingGroup(location)} /> )}

      </div>
    )
  }
}