export default class InputHandler {
  constructor(eventCodes, callback) {
    this.eventCodes = eventCodes;
    this.callback = callback;
    
    this.down = false;
  }

  listenForKeyDown(code) {
    if (this.eventCodes.includes(code)) this.handleEvent();
  }

  listenForKeyUp(code) {
    if (this.eventCodes.includes(code)) this.reset();
  }

  handleEvent() {
    if (this.down) return;
    
    this.down = true;
    this.callback();
  }

  reset() {
    this.down = false;
  }
}