import React from 'react'
import TitleTimeline from '../scripts/anime/TitleTimeline';

import shuffleArray from '../../utils/shuffleArray';

export default class TitleAnimation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      videoIndex: 0,
      isVideoContainerTop: false
    }

    this.animation = new TitleTimeline({
      allMusic: this.props.staticAudio.titleMusic,
      enableNextSlide: this.props.enableNextSlide,
      isEndTitle: this.props.flags.isEndTitle
    });

    const allVideoUrls = shuffleArray(this.props.staticImages.titleAnimation);
      
    this.allVideoElements = allVideoUrls.map((url) => {
      return (
        <video 
          src={url} 
          className="child webm-group" 
          onEnded={() => this.handleNextVideo()}
        />
      )
    });
  }

  componentDidMount() {
    this.animation.setElements();
    this.animation.animate();
    this.playVideo();
  }

  componentWillUnmount () {
    this.animation.tearDown();
  }

  pause() {
    this.refs.activeAnimation.pause();
    this.animation.pause();
  }

  resume() {
    this.refs.activeAnimation.play();
    this.animation.resume();
  }

  async handleNextVideo() {
    await this.incrementVideoIndex();
    this.animation.setElements();
    this.playVideo();
  }

  incrementVideoIndex() {
    if (this.state.videoIndex === this.allVideoElements.length - 1) {
      this.setState(prevState => ({ 
        videoIndex: 0, 
        isVideoContainerTop: !prevState.isVideoContainerTop 
      }));
      return;
    }

    if (this.state.videoIndex < this.allVideoElements.length) {
      this.setState(prevState => ({ 
        videoIndex: prevState.videoIndex + 1,  
        isVideoContainerTop: !prevState.isVideoContainerTop 
      }));
      return;
    }
  }

  playVideo() {
    // https://developers.google.com/web/updates/2017/06/play-request-was-interrupted
    const playPromise = this.refs.activeAnimation.play();
    if (playPromise !== undefined) {
      playPromise.then(() => {
        //
      })
      .catch(error => {
        //
      });
    }
  }

  render() {
    const blackBackground = <div id="black-background" className="child" />;

    const logo = <img id="logo" className="child" src={this.props.staticImages.logo} />;

    const activeAnimation = React.cloneElement(this.allVideoElements[this.state.videoIndex], { ref: "activeAnimation" });

    const movingVideoContainer = (
      <div id={this.state.isVideoContainerTop ? 'top-zone-container' : 'bottom-zone-container'} className="parent container">
        { activeAnimation }
      </div>
    );

    return (
      <div id="screen" className="parent">
        { blackBackground }
        { logo }
        { movingVideoContainer }
      </div>
    )
  }
}