import React from 'react';
import { Link, NavLink } from 'react-router-dom'
import FirebaseContext from 'auth/FirebaseContext';
import HomeLogo from 'img/imagarena_mark_white.png'

import Location from 'img/location.png'
import Organization from 'img/organization.png'
import Email from 'img/email.png'

import RectangleButton from '../ui_elements/rectangle/RectangleButton'

export default class Nav extends React.Component {
  static contextType = FirebaseContext;

  render() {
    const orgName = this.context.rolesData ? this.context.rolesData.cached_data.org_name : '';
    const locationName = this.context.rolesData ? this.context.rolesData.cached_data.favorite_location_name : '';
    const email = this.context.rolesData ? this.context.rolesData.email : '';

    return (
        <div>
          <div id="nav-bar">
          <Link to={'/'} ><img id="nav-logo" src={HomeLogo} /></Link>
          <NavLink activeStyle={{color: '#4885ed'}} className="nav-element big-nav-element" to={'/launcher'}>Launcher</NavLink>
          <NavLink activeStyle={{color: '#4885ed'}} className="nav-element big-nav-element" to={'/groupmanager'}>Manager</NavLink>
            <div id="right-nav">
              
              <div className="nav-info-element">
                <img src={Organization} />
                <h5 className="static-nav-element">{ orgName }</h5>
              </div>
              
              <div className="nav-info-element">
                <img src={Location} />
                <h5 className="static-nav-element">{ locationName }</h5>
              </div>
              
              <div className="nav-info-element">
                <img src={Email} />
                <h5 className="static-nav-element">{ email }</h5>
              </div>

              <div id="nav-buttons">
                <Link id="first-nav-button" to={'/user_profile'}><RectangleButton text={'User Profile'} color={'blue'} size={'xs'} /></Link>
                <Link id="" to={'/support'}><RectangleButton text={'Support'} color={'orange'} size={'xs'} /></Link>
                <RectangleButton selectCallback={() => this.context.logOut()} text={'Log Out'} color={'red'} size={'xs'} />
              </div>
            </div>
          </div>
        </div>
        
    )
  }
}
