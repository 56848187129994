import React from 'react'
import Slide from './Slide'
import PreloadAnimation from '../../../../../animations/components/PreloadAnimation';

export default class Preload extends Slide {
    constructor(options) {
        super(options);
    }

    getComponent() {
        return (
            <PreloadAnimation
                flags={this.flags}
                dynamicImages={this.dynamicImages}
                staticImages={this.staticImages}
                dynamicAudio={this.dynamicAudio}
                staticAudio={this.staticAudio}
            />
        )
    }
}
