import React from 'react';
import FirebaseContext from 'auth/FirebaseContext';
import { Link } from 'react-router-dom';
import urljoin from 'url-join';
import ImageUploader from 'react-images-upload';
import RectangleButton from '../ui_elements/rectangle/RectangleButton';

import LoadingMessage from '../misc_components/LoadingMessage';

import './photo_upload.css';

import BetaLogo from '../img/imagarena_text_logo.png'
import DevLogo from '../img/imagarena_text_logo_dev.png'

import ExitIcon from './img/exit.png'
import UploadIcon from './img/upload.png'
import ImageIcon from './img/image_placeholder.png'
import SuccessIcon from './img/success.png'
import WarningIcon from '../img/exclamation.png';
import AgainIcon from './img/again.png'

export default class PhotoUpload extends React.Component {
  static contextType = FirebaseContext;

  constructor(props) {
      super(props);
      this.state = {
        photos: [],
        responseStatus: null,
        activeMessage: 'default',
        photosUploaded: false
      }

      this.playthroughId = this.props.match.params.playthroughId;
  }

  onDrop(photos, pictureDataURLs) {
    const photosWithData = photos.map((picture, i) => {
      const str = pictureDataURLs[i];
      const formattedBase64String = str.substring(str.indexOf(",") + 1);
      return {
        name: picture.name,
        type: picture.type,
        data: formattedBase64String
      }
    });
    
    this.setState({ photos: photosWithData });
  }

  async uploadPhotos() {
    this.setState({ activeMessage: 'awaiting' });
    
    const data = { photos: this.state.photos };

    try {
      var response = await this.context.imagarenaApiClient.post(urljoin(process.env.REACT_APP_GROUP_MANAGER_SVC_ENDPOINT, 'playthroughs', this.playthroughId, 'photos'), data);
      this.setState({ responseStatus: response.status }, () => this.handleResponse());
    } catch(err) {
      console.log(err);
      this.setState({ activeMessage: "error" });
    }
  }

  handleResponse() {
    if (this.state.responseStatus === 200) return this.setState({ activeMessage: "success", photosUploaded: true });
  }

  reset() {
    this.setState({  
      photos: [],
      responseStatus: null,
      activeMessage: 'default',
      photosUploaded: false
    });
  }

  render() {
    const successMessage = (
      <div className="photo-upload-message">
        <img src={SuccessIcon} />
        <h2>Photos uploaded!</h2>
        <RectangleButton text="Upload more photos" selectCallback={() => this.reset()} size="xs" reverse={true} img={AgainIcon} />
      </div>
    );

    const errorMessage = (
      <div className="photo-upload-message">
        <img src={WarningIcon} />
        <h5>Something went wrong.  Try uploading the photos again.</h5>
      </div>
    );

    const defaultMessage = (
      <div className="photo-upload-message">
        <img id="placeholder-img" src={ImageIcon} />
        <h5>Choose some photos to upload for this session.</h5>
      </div>
    );

    const messageBox = {
      default: defaultMessage,
      awaiting: <LoadingMessage nested={true} message="Uploading photos..." />,
      success: successMessage,
      error: errorMessage
    }

    const photoUploadSection = (
      <div id="photo-upload-photos">
        <ImageUploader
          buttonStyles={{fontSize: 20, fontWeight: "bold", backgroundColor: "#4885ed"}}
          withIcon={false}
          label={''}
          buttonText='Choose Photos'
          onChange={(photos, blobData) => this.onDrop(photos, blobData)}
          withPreview={true}
        />
      </div>
    )

    const uploadButton = <RectangleButton selectCallback={() => this.uploadPhotos()} size='md' text="Upload Photos" color="green" img={UploadIcon} reverse={true} />;
    
    const logo = process.env.REACT_APP_ENV_NAME === 'beta' ? BetaLogo : DevLogo;

    return (
      <div>
        <div id="photo-upload-nav">
          <img src={logo} />
          <Link to={'/'}><RectangleButton text={'Exit'} size={'sm'} img={ExitIcon} reverse={true} /></Link>
        </div>

        <div id="photo-upload-main">
          <div id="upload-button-wrapper" >
            { this.state.photos.length && !this.state.photosUploaded ? uploadButton : '' }
          </div>
          <div id="photo-upload-message-box">
            { messageBox[this.state.activeMessage] }
          </div>
        </div>

        { !this.state.photosUploaded ? photoUploadSection : '' }

      </div>
    )
  }
}
