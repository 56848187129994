import urljoin from 'url-join'

export default async function createPlaythrough(config, httpClient) {
  const data = {
    userSelectionConfig: {
      group: config.groupId,
      set: config.setId,
      upgrade: config.upgradeId,
      items: config.itemsId,
      shapes: config.shapesId,
      module: config.moduleId
    }
  }

  const response = await httpClient.post(urljoin(process.env.REACT_APP_SEQUENCE_SVC_ENDPOINT, 'sequence/'), data);
  return response.data.playthroughId;
}