import React from 'react';
import firebase from 'firebase';
import FirebaseContext from 'auth/FirebaseContext';
import Bowser from 'bowser';
import { Redirect } from 'react-router-dom'

import ChromeLogo from 'img/chrome_logo.png'
import RectangleButton from '../ui_elements/rectangle/RectangleButton'

import BetaLogo from 'img/imagarena_text_logo.png'
import DevLogo from '../img/imagarena_text_logo_dev.png'

export default class App extends React.Component {
  static contextType = FirebaseContext;

  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      errorString: "",
      authUser: null,
    }

    this.browser = Bowser.getParser(window.navigator.userAgent);
    this.browserName = this.browser.getBrowserName();
    this.supportedBrowsers = ['Chrome'];
  }

  handleEmailChange(e) {
    this.setState({
      email: e.target.value
    });
  }

  handlePasswordChange(e) {
    this.setState({
      password: e.target.value
    });
  }

  async logIn() {
    this.setState({error: ""});

    const {email, password} = this.state;
    try {
      await firebase.auth().signInWithEmailAndPassword(email, password);
    }
    catch(error) {
      this.setState({error: `${error.code} - ${error.message}`});
    }
  }

  render() {
    const logInScreen = (
      <div id="login-fields">
        <h2 style={{color: "red"}}>{this.state.error}</h2>
        <input onChange={(e) => this.handleEmailChange(e)}    placeholder="Email"    type="email"    value={this.state.email} /><br/>
        <input onChange={(e) => this.handlePasswordChange(e)} placeholder="Password" type="password" value={this.state.password} /><br/>
        <RectangleButton selectCallback={() => this.logIn()} text={'LOG IN'} size={'sm'} />
      </div>
    );

    const browserNotSupported = (
      <div id="browser-message">
        <h1>This app does not currently support {this.browserName }.</h1>
        <h3>During Beta, this app will only run in Google Chrome.</h3>
        <a href="https://www.google.com/chrome/">
          <div id="chrome-link" >
            <img src={ChromeLogo} />
            <h4>Click Here to Download Google Chrome</h4>
          </div>
        </a>
        <h5>If you do not have Chrome installed on your computer, follow the link above to get started.</h5>
      </div>
    );

    const loginBox = this.context.authUser !== null ? <Redirect to='/' /> : logInScreen;
    const browserIsSupported = this.supportedBrowsers.includes(this.browser.getBrowserName());

    const logo = process.env.REACT_APP_ENV_NAME === 'beta' ? BetaLogo : DevLogo;

    return (
      <div id="login-container">
        <img id="home-logo" src={logo} />
        { browserIsSupported ? loginBox : browserNotSupported }
      </div>
    );
  }
}
