export default function loadAudioPromise(audio) {

        if (!audio) return;

        // because of how howler stores audio paths
        const howlerAudio = audio.src[0]

        return new Promise((resolve, reject) => {
            const loadingAudio  = new Audio();
            loadingAudio.src = howlerAudio;
            loadingAudio.addEventListener('canplaythrough', () => {
                // console.log(`loaded ${loadingAudio.src}`);
                resolve();
            });
            
            loadingAudio.onerror = (err) => {
                console.error(`Error while attempting to load audio ${loadingAudio.src}`);
                reject(err);
            }
    });
    
}