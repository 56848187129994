import LogRocket from 'logrocket';

export function initLogRocket() {
  const env = process.env.REACT_APP_ENV_NAME;
  LogRocket.init(`czqtlc/${env}-imagarena`);  
}

export function setLogRocketIdentity(rolesData) {
  LogRocket.identify(rolesData.auth_uuid, {
    name: rolesData.name,
    email: rolesData.email,
    permission: rolesData.permission,
    organization: rolesData.cached_data.org_name
  });
}