import React from 'react'
import { Route, Redirect } from "react-router-dom";
import FirebaseContext from 'auth/FirebaseContext';

export default function ProtectedRoute(props) {
  return (
    <FirebaseContext.Consumer>
      {
        ({authUser}) => {
          if (authUser)
            return <Route path={props.path} component={props.component}>{props.children}</Route>
          else {
            return <Redirect to='/login' />
          }
        }
      }
    </FirebaseContext.Consumer>
    )
}