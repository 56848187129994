import anime from 'animejs'
import AudioPlayer from '../audio_utils/AudioPlayer.js';
import generateCollection from '../../../otaku/src/scripts/generateCollection.js';

import collection from '../../screen_collections/challengeCollection.json'

export default class ChallengeTimeline {
    constructor(options) {
        this.audio = new AudioPlayer();
        this.activeAnimations = {};
        this.enableNextSlide = options.enableNextSlide;

        this.staticAudio = options.staticAudio;

        this.collection = generateCollection(collection);
        this.elements = this.collection.elements;
    }

    tearDown() {
        Object.values(this.activeAnimations).forEach((timeline) => {
            timeline.pause();
        });
        
        this.audio.killAllAudio();
        this.audio = null;
    }

    pause() {
        Object.values(this.activeAnimations).forEach(timeline => timeline.pause());
        this.audio.pause();
    }

    resume() {
        Object.values(this.activeAnimations).forEach(timeline => timeline.play());
        this.audio.resume();
    }

    animate() {
        this.collection.setAllElements(anime);

        const timeline = anime.timeline({easing: 'linear'});
        this.activeAnimations.timeline = timeline;

        timeline
        .add({
            targets: this.elements.challengeLogo.tag,
            keyframes: [
                {
                    scale: 1,
                    duration: 1000,
                    easing: 'easeOutElastic(1, .5)'
                },
                {
                    scale: 0,
                    duration: 200,
                    easing: 'easeOutQuad',
                    delay: 250
                }
            ],
            changeBegin: () => { 
                this.audio.playClip(this.staticAudio.challengeHit);
            }
        })
        .add({
            targets: this.elements.challengeCategory.tag,
            scale: 1,
            duration: 1000,
            easing: 'easeOutElastic(1, .5)',
            changeBegin: () => this.audio.playClip(this.staticAudio.categoryHit)
        })
        .add({
            targets: this.elements.challengeCategory.tag,
            translateY: this.elements.challengeCategory.setY(-42),
            scale: 0.5,
            duration: 1000,
            easing: 'easeOutElastic(1, .5)',
            delay: 1000
        })
        .add({
            targets: this.elements.challengeContent.tag,
            duration: 250,
            opacity: 1,
            changeBegin: () => { 
                this.audio.playClip(this.staticAudio.open);
                setTimeout(() => this.enableNextSlide(), 3000);
            },
            complete: () => {
                delete this.activeAnimations.timeline
            }
        }, '-=1000')
    }
}