import Challenge from './sequence/sequence_classes/slides/Challenge'
import Clear from './sequence/sequence_classes/slides/Clear'
import Intro from './sequence/sequence_classes/slides/Intro'
import Map from './sequence/sequence_classes/slides/Map'
import ModePreview from './sequence/sequence_classes/slides/ModePreview'
import Outro from './sequence/sequence_classes/slides/Outro'
import Preload from './sequence/sequence_classes/slides/Preload'
import Presentation from './sequence/sequence_classes/slides/Presentation'
import Preview from './sequence/sequence_classes/slides/Preview'
import Timer from './sequence/sequence_classes/slides/Timer'
import Title from './sequence/sequence_classes/slides/Title'

export default class Sequence {
  constructor(encodedSequence) {
    this.encodedSequence = encodedSequence;
    
    this.slides = [];
    this.index = 0;
  }

  static createSlide(options) {
    const constructors = {
      challenge: Challenge,
      clear: Clear,
      intro: Intro,
      map: Map,
      modepreview: ModePreview,
      outro: Outro,
      preload: Preload,
      presentation: Presentation,
      preview: Preview,
      timer: Timer,
      title: Title
    }

    const slideClass = constructors[options.metaData.type];
    
    if (!slideClass) throw new TypeError(`'${options.metaData.type}' is not a valid Slide type`);
    
    return new slideClass(options);
  }

  decodeSequence() {
    this.slides = this.encodedSequence.map((responseObject) => {
      return Sequence.createSlide(responseObject)
    });
  }

  getCurrentSlide() {
    return this.slides[this.index];
  }

  getNextSlide() {
    return this.slides[this.index + 1];
  }

  getPreviousSlide() {
    return this.slides[this.index - 1];
  }

  increment() {
    if (this.index >= this.slides.length - 1) return;
    this.index += 1;
  }

  decrement() {
    if (this.index === 0) return;
    this.index -= 1;
  }

  // 1/3/20 CHECK: this is just for inspector, no utility in production...
  skipToEnd() {
    this.index = this.slides.length - 1;
  }
}