import React from 'react'

export default class ScreenText extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div id={this.props.id} className={`child ${this.props.className}`}>
                <svg viewBox="0 0 160 90" height="100%" width="100%">
                    <foreignObject width="160.25" height="90.25">
                        <div className="text-wrapper">
                            <div>{this.props.text}</div>
                        </div>
                    </foreignObject>
                </svg>
            </div>
        )
    }
}