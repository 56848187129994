import randomChoiceFromArray from '../../utils/randomChoiceFromArray'

import Adjectives from './data/adjectives.json'
import Nouns from './data/nouns.json'

export default function generateRandomTitle(maximumStringLength) {
  const subStringArrays = [Adjectives, Nouns];
  
  let randomString = generateRandomString(subStringArrays);
  
  while (randomString.length > maximumStringLength) {
    randomString = generateRandomString(subStringArrays);
  }

  return randomString
}

function generateRandomString(subStringArrays) {
  const randomSubStrings = subStringArrays.map((arr) => { 
    return randomChoiceFromArray(arr)
  })

  const combinedSubstrings = combineSubstrings(randomSubStrings);
  const prefixArticle = getArticle(combinedSubstrings)

  const chanceOfAddingArticle = 0.85

  if (Math.random() <= chanceOfAddingArticle) {
    return prefixArticle + " " + combinedSubstrings
  }

  return combinedSubstrings
}

function combineSubstrings(subStrings) {
  let randomString = ""
  
  subStrings.forEach((subString, i) => {
    const upperCaseString = subString.charAt(0).toUpperCase() + subString.slice(1)
    if (i === subStrings.length - 1) {
      randomString += upperCaseString
    } else {
      randomString += upperCaseString + " "
    }
  })

  return randomString
}

function getArticle(string) {
  const validArticles = ['The']
  
  if (!string.endsWith('s')) {
    const vowelRegex = '^[aieouAIEOU].*'
    if (string.match(vowelRegex)) {
      validArticles.push('An')
    } else {
      validArticles.push('A')
    }
  }

  return randomChoiceFromArray(validArticles)
}

