import anime from 'animejs'
import AudioPlayer from '../audio_utils/AudioPlayer';
import generateCollection from '../../../otaku/src/scripts/generateCollection';
import collection from 'animations/screen_collections/slideshowCollection.json';

export default class ClearAnimation {
    constructor(options) {
        this.audio = new AudioPlayer(collection);
        this.sfxFiles = options.sfxFiles;

        this.collection = generateCollection(collection);
        this.elements = this.collection.elements;

        this.timeline = anime.timeline({});
    }

    tearDown() {
        this.audio.killAllAudio();
        this.audio = null;
    }

    animate() {
        this.collection.setAllElements(anime);
    }

    // photoTransition() {
    //     this.audio.playClip(this.sfxFiles['mapSwipe'])
    // }
}
