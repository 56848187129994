import loadImagePromise from '../../../../../utils/loadImagePromise'
import loadAudioPromise from '../../../../../utils/loadAudioPromise'

export default class Slide {
    constructor(options) {
        this.flags = options.animationData.flags || {};
        this.dynamicImages = options.assets.dynamicImages || {};
        this.staticImages = options.assets.staticImages || {};
        this.dynamicAudio = options.assets.dynamicAudio || {};
        this.staticAudio = options.assets.staticAudio || {};
        
        this.loaded = false;

        if (new.target === Slide) {
            throw new TypeError('Cannot construct Abstract Base Class "Slide"');
        }
    }

    // first two args are unused in base method but are kept so function signatures are more consistent
    async loadAssets(playthrough, usedPlaythroughIds, usedDefaultPhotoIds, httpClient, willLoadMore=false) {
        const allImages = [
            ...Object.values(this.dynamicImages),
            ...Object.values(this.staticImages)
        ];

        const allAudio = [
            ...Object.values(this.dynamicAudio),
            ...Object.values(this.staticAudio)
        ];

        const loadingPromises = [
            ...Object.values(allImages).map(loadImagePromise),
            ...Object.values(allAudio).map(loadAudioPromise)
        ];

        await Promise.all(loadingPromises);
        if (!willLoadMore) // Don't mark loaded as true if there are going to be more assets loaded after this
          this.loaded = true;
    }
}
