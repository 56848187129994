import React from 'react';
import FirebaseContext from 'auth/FirebaseContext';
import SiteRouter from './SiteRouter';

// 2/5/20 TODO: spin global error handling into it's own context
export default function UserWrapper(props) {
    return (
      <FirebaseContext.Provider value={props}>
        <SiteRouter error={props.error} clearError={props.clearError} />
      </FirebaseContext.Provider>
    );
  }