import React from 'react'

import IntroTimeline from '../scripts/anime/IntroTimeline'

export default class Intro extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        isPaused: false
    }
    this.animation = new IntroTimeline({
        enableNextSlide: this.props.enableNextSlide,
        dynamicAudio: this.props.dynamicAudio
    });
  }

  componentDidMount() {
    this.animation.animate(document.getElementById("vid"));

    this.intervalId = setInterval(() => {
        this.animation.checkTime(document.getElementById("vid"));
    }, 100);
  }

  componentWillUnmount() {
    this.animation.tearDown();
    clearInterval(this.intervalId);
  }

  pause() {
    this.animation.pause(document.getElementById("vid"));
  }

  resume() {
    this.animation.resume(document.getElementById("vid"));
  }

  render() {

    // 
    
    const preRenderedVideo = <video id="vid" className="child canvas " src={this.props.staticImages.introCinematic} type="video/mp4" />;

    return (
      <div id="screen" className="parent">

        { preRenderedVideo }
    
        {/*  */}

      </div>
    )
  }
}