import React from 'react';

import PresentationTimeline from '../scripts/anime/PresentationTimeline';
import Slideshow from './shared_animations/Slideshow'

export default class PresentationAnimation extends React.Component {
    constructor(props) {
        super(props);
        this.animation = new PresentationTimeline({
            enableNextSlide: this.props.enableNextSlide
        });
    }

    componentDidMount() {
        this.animation.animate();
    }

    componentWillUnmount() {
        this.animation.tearDown();
    }

    render() {

        const topBar = <div id="top-bar" className="child" />

        const logo = <img id="logo" className="child" src={this.props.staticImages.logo} /> 

        

        return (
            <div id="screen" className="parent">

                <Slideshow
                    photos={this.props.slideshowData.photos}
                    showPhotoTitle={false}
                />

                { topBar }

                { logo }

                

            </div>
        );
    }
}
