export default function loadImagePromise(img) {
  if (!img) return;
  
  const fileExtension = img.split('.').pop();

  if (fileExtension === 'mp4' || fileExtension === 'webm') {
    return mediaLoader(img);
  }

  return imageLoader(img);
}

function mediaLoader(img) {
  return new Promise((resolve, reject) => {
    const loadingVideo = document.createElement("video");
    loadingVideo.setAttribute('src', img);
    loadingVideo.addEventListener('canplaythrough', () => {
      // console.log(`loaded ${loadingVideo.src}, ready for playback`);
      resolve();
    })

    loadingVideo.onerror = (err) => {
      console.error(`Error while attempting to load video ${loadingVideo.src}`);
      reject(err);
    }
  });
}

function imageLoader(img) {
  return new Promise((resolve, reject) => {
    const loadingImage = new Image();
    loadingImage.src = img;
    loadingImage.onload = () => {
      // console.log(`loaded ${loadingImage.src}`);
      resolve();
    }

    loadingImage.onerror = (err) => {
      console.error(`Error while attempting to load image ${loadingImage.src}`);
      reject(err);
    }
  });
}
