import React from 'react'
import Slide from './Slide'
import TitleAnimation from '../../../../../animations/components/TitleAnimation';

import loadImagePromise from '../../../../../utils/loadImagePromise';
import loadAudioPromise from '../../../../../utils/loadAudioPromise';

export default class Title extends Slide {
    constructor(options) {
        super(options);
    }

    async loadAssets() {
        const imageAssets = [...this.staticImages.titleAnimation, this.staticImages.logo];
        const audioAssets = this.staticAudio.titleMusic;

        const imagePromise = Promise.all(imageAssets.map((asset) => loadImagePromise(asset)));
        const audioPromise = Promise.all(audioAssets.map((asset) => loadAudioPromise(asset)));

        await Promise.all([imagePromise, audioPromise]);
    }

    getComponent(playthrough) {
        return ( 
            <TitleAnimation
                flags={this.flags}
                dynamicImages={this.dynamicImages}
                staticImages={this.staticImages}
                dynamicAudio={this.dynamicAudio}
                staticAudio={this.staticAudio}
            />
        )
    }
}