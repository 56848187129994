import urljoin from 'url-join'
import FirebaseContext from 'auth/FirebaseContext';

import React from 'react';
import RectangleButton from '../../ui_elements/rectangle/RectangleButton'

export default class EditGroup extends React.Component {
  static contextType = FirebaseContext;

  constructor(props) {
    super(props);
    this.state = {
        group: this.props.group,
        pendingName: this.props.group.name, // Don't want to modify it in place
        exceededLength: false
    }
  }

  async saveGroup() {
    const data = {
      updates: {
        name: this.state.pendingName
      }
    }

    const response = await this.context.imagarenaApiClient.patch(urljoin(process.env.REACT_APP_GROUP_MANAGER_SVC_ENDPOINT, 'groups', this.state.group._id), data);
    this.props.refresh();
    this.props.clearEditingGroup();
  }

  setNewGroupName(event) {
    if (event.target.value.length > 35 ) {
      event.target.value.slice(0, 35);
      this.setState({ exceededLength: true });
      return;
    }

    this.setState({ pendingName: event.target.value });
  }

  render () {
    return (
      <div>
        <div className="edit-container">
          <input onChange={(event) => this.setNewGroupName(event)} value={this.state.pendingName}/>
          <RectangleButton text={'SAVE'} clickSFX={'positive'} color={'blue'} selectCallback={() => this.saveGroup()} size={'sm'} />
          <RectangleButton clickSFX={'negative'} text={'CANCEL'} color={'red'} selectCallback={() => this.props.clearEditingGroup()  } size={'sm'} />
        </div>
        { this.state.exceededLength ? <h5 className="character-warning">Group Names are limited to 35 characters</h5> : '' }
      </div>

    )
  }
}
