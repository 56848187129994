import anime from 'animejs'
import { Howl } from 'howler'

import generateCollection from '../../../otaku/src/scripts/generateCollection';

import PauseSfx from '../assets/pause_sfx.wav';
import collection from './overlayCollection.json';

export default class OverlayAnimations {
    constructor() {
        this.collection = generateCollection(collection);
        this.elements = this.collection.elements;

        this.audio = {
            pause: { src: [PauseSfx], volume: 0.25 }
        }
    }

    setElements() {
        this.collection.setAllElements(anime);
    }

    hideAll() {
        this.hidePause();
        this.hideReady();
        this.hideLoading();
    }
    
    showPause() {
        const OVERLAY_OPACITY = 0.95;
        const pauseSFX = new Howl(this.audio.pause);

        anime({
            targets: this.elements.pause.tag,
            opacity: OVERLAY_OPACITY,
            duration: 250,
            easing: 'linear',
            begin: () => pauseSFX.play()
        });
    }

    hidePause() {
        anime({
            targets: this.elements.pause.tag,
            opacity: 0,
            duration: 250,
            easing: 'linear'
        });
    }

    showWaitMessage() {
        anime({
            targets: this.elements.skip.tag,
            easing: 'linear',
            keyframes: [
                { duration: 10, opacity: 0.85 },
                { delay: 600, duration: 100, opacity: 0}
            ],
        })
    }

    showLoadingMessage() {
        anime({
            targets: this.elements.loadingMessage.tag,
            easing: 'linear',
            keyframes: [
                { duration: 10, opacity: 0.85 },
                { delay: 600, duration: 100, opacity: 0}
            ],
        })
    }

    showCompleteMessage() {
        anime({
            targets: this.elements.complete.tag,
            easing: 'linear',
            keyframes: [
                { duration: 10, opacity: 0.85 },
                { delay: 600, duration: 100, opacity: 0}
            ],
        })
    }

    showReady() {
        this.hideLoading();

        anime({
            targets: this.elements.ready.tag,
            loop: true,
            keyframes: [
                { opacity: 1 },
                { opacity: 0 }
            ],
            easing: 'linear'
        })
    }

    showLoading() {
        anime({
            targets: this.elements.loading.tag,
            opacity: 1,
            easing: 'linear',
            duration: 500
        })
    }

    showActiveIndicator() {
        anime({
            targets: this.elements.active.tag,
            keyframes: [
                { duration: 10, opacity: 1 },
                { delay: 600, opacity: 0 }
            ],
            easing: 'linear'
        })
    }

    hideReady() {
        anime.remove(this.elements.ready.tag);
        anime.set(this.elements.ready.tag, { opacity: 0 });
    }

    hideLoading() {
        anime.remove(this.elements.loading.tag);
        anime.set(this.elements.loading.tag, { opacity: 0 });
    }
}