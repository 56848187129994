import React from 'react'
import Slide from './Slide'
import loadImagePromise from '../../../../../utils/loadImagePromise'
import PresentationAnimation from '../../../../../animations/components/PresentationAnimation';

import noPhotoGraphic from "animations/assets/oops.png"

import urljoin from 'url-join';

export default class Presentation extends Slide {
    constructor(options) {
        super(options);
    }

    static get errorPhoto() {
        return [{ url: noPhotoGraphic, title: "oops" }];
    }

    async loadAssets(playthroughId, usedPlaythroughIds, usedDefaultPhotoIds, httpClient) {
      await super.loadAssets(playthroughId, usedPlaythroughIds, usedDefaultPhotoIds, httpClient, true);

      try {
        var response = await httpClient.get(
          urljoin(process.env.REACT_APP_GROUP_MANAGER_SVC_ENDPOINT, 'playthroughs', playthroughId),
          { globalErrorDisabled: true }
        );
        const playthrough = response.data.playthrough;
        this.slideshowData = {
          photos: playthrough.photos.length > 0 ? playthrough.photos : Presentation.errorPhoto
        }
      } catch(err) {
        console.error(err.response.data);
        
      }

      try {
        await this.loadSlideshowPhotos();
        // 1/13/20 CHECK: what is this?
        if (this.slideshowData.photos.length > 0) {
          this.loaded = true;
        }
      } catch(err) {
        this.slideshowData = {
          photos: Presentation.errorPhoto
        }
        await this.loadSlideshowPhotos();
        console.error(err);
        console.error('org_photos cannot be found.  this is most likely due to a playthrough document referencing a nonexistent bucket url.')
      }
    }

    async loadSlideshowPhotos() {
      await Promise.all(this.slideshowData.photos.map((photo) => loadImagePromise(photo.url)));
    }

    getComponent() {
      return (
        <PresentationAnimation
          flags={this.flags}
          dynamicImages={this.dynamicImages}
          staticImages={this.staticImages}
          dynamicAudio={this.dynamicAudio}
          staticAudio={this.staticAudio}

          slideshowData={this.slideshowData}
        />
      )
    }
}
