import React from 'react'

import BackArrow from './subcomponents/BackArrow';
import ReactTooltip from 'react-tooltip'
import RectangleButton from '../../ui_elements/rectangle/RectangleButton'

import Info from 'img/info.png'
import LaunchIcon from 'img/launch_tabs.png'

function ReviewSlot(props) {
  return (
    <div className="review-slot">
        <h2>{props.categoryName}</h2>
        <h2 className="cat-name">{props.selection.displayData.title}</h2>
    </div>
  )
}

export default class ReviewScreen extends React.Component {
	constructor(props) {
			super(props);
	}

	render() {
			
		const tipText = "Review all of the options for this Level, then click 'Launch Level'!"

		return (
			<div className="launcher-container">
				
				<BackArrow goBack={this.props.goBack} />
				
				<div className="main">
					<div id="review-column">
						
						<div className="title-section">
							<ReactTooltip id="tip" className="tip">{tipText}</ReactTooltip>
							<div className="title">Level Summary</div>
							<img src={Info} data-tip data-for="tip"/>
						</div>

						<div id="material-review-section">
							<img src={this.props.materials.set.metadata.display_info.graphic}/>
							<h1>+</h1>
              <img src={this.props.materials.upgrade.metadata.display_info.graphic}/>
              <h1>+</h1>
							<div id="item-shape-review-section">
								<img src={this.props.materials.items.metadata.display_info.graphic}/>
								<img src={this.props.materials.shapes.metadata.display_info.graphic}/>
							</div>
						</div>

						<div id="text-review">
							<ReviewSlot categoryName={"Group:"} selection={{displayData: {title: this.props.group.name}}} />
							<ReviewSlot categoryName={"Module:"} selection={{displayData: {title: this.props.level.display_info.name}}} />
							<ReviewSlot categoryName={"Set:"} selection={{displayData: {title: this.props.materials.set.metadata.display_info.name}}} />
							<ReviewSlot categoryName={"Items:"} selection={{displayData: {title: this.props.materials.items.metadata.display_info.name}}} />
							<ReviewSlot categoryName={"Shapes:"} selection={{displayData: {title: this.props.materials.shapes.metadata.display_info.name}}} />
						</div>
								
						<RectangleButton size={'lg'} text={'Launch Level'} selectCallback={this.props.getPlaythroughId} color={'blue'} img={LaunchIcon} />
					
					</div>
				</div>
			</div>
		)
  }
}
