export default class ScreenElement {
    constructor(options) {
        this.tag = options.tag;
        this.x = options.x !== undefined ? options.x : 0;
        this.y = options.y !== undefined ? options.y : 0;
        this.scale = options.scale !== undefined ? options.scale : 1; // bug: if options.scale is 0, it will evaluate to 'falsy', then set to 1
        this.opacity = options.opacity !== undefined ? options.opacity : 1;
        this.rotation = options.rotation !== undefined ? options.rotation : 0;
        
        if (new.target === ScreenElement) {
            throw new TypeError(" Cannot construct Abstract Base Class 'ScreenElement' ")
        }
    }
}