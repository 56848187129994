import React from 'react';
import { Link } from 'react-router-dom';

import RectangleButton from '../../ui_elements/rectangle/RectangleButton'

import Camera from 'img/camera_white.png'

export default class PlaythroughSelector extends React.Component {
    constructor(props) {
      super(props);
    }

    render () {        
      return (
        <div>
          <div className="playthrough-selector">  
            {/* <h3><span className="blocked-text">Play Date:</span> {this.playthrough.startDate}</h3> */}
            {/* <h3><span className="blocked-text">Time Started:</span> {this.playthrough.startTime}</h3> */}
            {/* <h3>Level Config: {JSON.stringify(this.playthrough.config)}</h3> */}
            
            <Link to={`/photoviewer/${this.props.playthrough._id}`}><div id="button-hack"><RectangleButton text={`VIEW PHOTOS - ${this.props.playthrough.photos.length}`} size={'sm'} color={'blue'} img={Camera} reverse={true} /></div></Link>
          </div>
        </div>
        
      )
    }
  }