import firebase from 'firebase';

export function firebaseInit(options) {
  import(`config/${process.env.REACT_APP_ENV_NAME}_firebase_config.json`).then( config => {
    firebase.initializeApp(config);
    firebase.auth().onAuthStateChanged(async (authUser) => {
      // make this callback, pass in update user
      await options.updateCallback(authUser);
      options.loadedCallback();
    });
  });
}

export function firebaseLogOut() {
  firebase.auth().signOut();
}

