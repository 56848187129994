import ScreenElement from "./ScreenElement";

export default class DivChild extends ScreenElement {
    constructor(options) {
        super(options);
        this.height = options.height !== undefined ? options.height : 25;
        this.width = options.width !== undefined ? options.width : 25;
        this.color = options.color !== undefined ? options.color : 'red';
    }

    setX(x) {
        return `${ 100 / this.width * x }%`;
    }

    setY(y) {
        return `${ -(100 / this.height * y) }%`; // added the '-' negative to flip the y coordinates.  why was y-negative 'up' in the first place?
    }

    getStartingPosition() {
        return  {
            width: `${this.width}%`,
            height: `${this.height}%`,
            translateX: this.setX(this.x),
            translateY: this.setY(this.y),
            background: this.color,
            opacity: this.opacity,
        }
    }
}