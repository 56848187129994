import React from 'react'
import Slide from './Slide'
import IntroAnimation from '../../../../../animations/components/IntroAnimation';

export default class Intro extends Slide {
    constructor(options) {
        super(options);
    }

    getComponent(playthrough) {
        return ( 
            <IntroAnimation
                flags={this.flags}
                dynamicImages={this.dynamicImages}
                staticImages={this.staticImages}
                dynamicAudio={this.dynamicAudio}
                staticAudio={this.staticAudio}
            />
        )
    }
}