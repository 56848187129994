import React from 'react';

import ChallengeTimeline from '../scripts/anime/ChallengeTimeline'
import ChallengeContent from './shared_packets/ChallengeContent'
 
export default class Challenge extends React.Component {
    constructor(props) {
        super(props);
        this.animation = new ChallengeTimeline({
            enableNextSlide: this.props.enableNextSlide,
            staticAudio: this.props.staticAudio
        })
    }

    componentDidMount() {
        this.animation.animate();
    }

    componentWillUnmount() {
        this.animation.tearDown();
    }

    pause() {
        this.animation.pause();
    }

    resume() {
        this.animation.resume();
    }

    render() {

        const challengeLogo = <img id="challenge-logo"  className="child" src={this.props.staticImages.challengeLogo} />;

        const challengeCategory = <img id="challenge-category"  className="child" src={this.props.dynamicImages.challengeCategory} />;

        const challengeContent = (
            <ChallengeContent
                challengeText={this.props.flags.challengeText}
                challengeImage={this.props.dynamicImages.challengeImage}
            />
        )

        

        return (

            <div id="screen" className="parent">

                { challengeLogo }

                { challengeCategory }

                { challengeContent }

            </div>

        );
    }
}
