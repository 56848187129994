import React from 'react'
import urljoin from 'url-join'
import { Redirect } from 'react-router-dom'

import '../launcher.css'

import GroupSelect from './GroupSelect'
import LevelSelect from './LevelSelect'
import MaterialSelect from './MaterialSelect'
import ReviewScreen from './ReviewScreen'
import ExperienceSelect from './ExperienceSelect'

import createPlaythrough from '../scripts/createPlaythrough'
import FirebaseContext from 'auth/FirebaseContext';

import LoadingMessage from '../../misc_components/LoadingMessage';

export default class GameLauncher extends React.Component {
  static contextType = FirebaseContext;

  constructor(props){
    super(props);
    this.state = {
      userSelections: {
        group: null,
        level: null,
        materials: {
          set: null,
          upgrade: null,
          items: null,
          shapes: null
        }
      },
      navigation: {
        currentIndex: 0,
        experience: null,
        activeMode: null,
        materialsReady: true,
        modalIsOpen: false,
      },
      activeView: 'launcherSteps',
      playthroughId: ''
    }

    this.incrementIndex = this.incrementIndex.bind(this);
    this.decrementIndex = this.decrementIndex.bind(this);

    this.selectGroup = this.selectGroup.bind(this);
    this.changeExperience = this.changeExperience.bind(this);
    this.selectMaterial = this.selectMaterial.bind(this);
    this.changeActiveMode = this.changeActiveMode.bind(this);
    this.selectLevel = this.selectLevel.bind(this);

    this.confirmMaterials = this.confirmMaterials.bind(this);
    this.getPlaythroughId = this.getPlaythroughId.bind(this);
  }

  incrementIndex() {
    this.setState(prevState => ({
      navigation: {
        ...prevState.navigation,
        currentIndex: this.state.navigation.currentIndex += 1
      }
    }));
  }

  decrementIndex() {
    this.setState(prevState => ({
      navigation: {
        ...prevState.navigation,
        currentIndex: this.state.navigation.currentIndex -= 1
      }
    }));
  }

  selectGroup(group) {
    this.setState(prevState => ({
      userSelections: {
        ...prevState.userSelections,
        group
      }
    }));
  }

  changeExperience(experience) {
    this.setState(prevState => ({
      navigation: {
        ...prevState.navigation,
        experience
      }
    }));
  }

  selectMaterial(category, data) {
    this.setState(prevState => ({
      userSelections: {
        ...prevState.userSelections,
        materials: {
          ...prevState.userSelections.materials,
          [category]: data
        }
      }
    }));
  }

  changeActiveMode(modeId) {
    this.selectLevel(null);
    this.setState(prevState => ({
      navigation: {
        ...prevState.navigation,
        activeMode: modeId,
      }
    }));
  }

  selectLevel(level) {
    this.setState(prevState => ({
      userSelections: {
        ...prevState.userSelections,
        materials: { ...prevState.userSelections.materials },
      level,
      },
      navigation: {
        ...prevState.navigation,
        materialsReady: level == null || !level.hasRequiredLessons
      }
    }));

    if (level && level.hasRequiredLessons) this.openModal();
  }

  openModal() {
    this.setState(prevState => ({
      navigation: {
        ...prevState.navigation,
        modalIsOpen: true
      }
    }));
  }

  closeModal() {
    this.setState(prevState => ({
      navigation: {
        ...prevState.navigation,
        modalIsOpen: false
      }
    }));
  }

  confirmMaterials() {
    this.setState(prevState => ({
      navigation: {
        ...prevState.navigation,
        materialsReady: true
      }
    }));
  }

  async getPlaythroughId() {
    this.setState({ activeView: 'loadingPlaythrough' });
    const config = this.createConfig();
    try {
      const playthroughId = await createPlaythrough(config, this.context.imagarenaApiClient);
      this.setState({ playthroughId }, () => this.launchGameWindows());
    } catch(err) {
      console.log(err)
      this.setState({ activeView: 'playthroughLoadError' });
    }
  }

  launchGameWindows() {
    this.setState({ activeView: 'photoUpload' });
    window.open(urljoin(window.location.origin, 'player', this.state.playthroughId));
  }

  createConfig() {
    return {
      groupId: this.state.userSelections.group._id,
      setId: this.state.userSelections.materials.set.inventory_doc._id,
      upgradeId: this.state.userSelections.materials.upgrade.inventory_doc._id,
      itemsId: this.state.userSelections.materials.items.inventory_doc._id,
      shapesId: this.state.userSelections.materials.shapes.inventory_doc._id,
      moduleId: this.state.userSelections.level.pack_uid
    }
  }

  render() { 
    console.log(this.state.userSelections);

    const launcherSteps = [
      <GroupSelect
        goNext={this.incrementIndex}
        selectGroup={this.selectGroup}
        selectedGroup={this.state.userSelections.group}
        valid={this.state.userSelections.group != null}
      />,
      // <ExperienceSelect
      //   goNext={this.incrementIndex}
      //   goBack={this.decrementIndex}
      //   changeExperience={this.changeExperience}
      //   selectedExperience={this.state.navigation.experience}
      //   launchWorkshop={() => this.launchAmbient('workshop')}
      // />,
      <MaterialSelect
        goNext={this.incrementIndex}
        goBack={this.decrementIndex}
        selectMaterial={this.selectMaterial}
        selectedMaterials={this.state.userSelections.materials}
        valid={
          this.state.userSelections.materials.set !== null &&
          this.state.userSelections.materials.upgrade !== null &&
          this.state.userSelections.materials.items !== null &&
          this.state.userSelections.materials.shapes !== null
        }

        cachedUpgradeIds={this.state.userSelections.group && this.state.userSelections.group.cached_config_ids.upgrade ? this.state.userSelections.group.cached_config_ids.upgrade : []} 
        cachedItemIds={this.state.userSelections.group && this.state.userSelections.group.cached_config_ids.items ? this.state.userSelections.group.cached_config_ids.items : []} 
        cachedShapeIds={this.state.userSelections.group && this.state.userSelections.group.cached_config_ids.shapes ? this.state.userSelections.group.cached_config_ids.shapes : []} 
      />,
      <LevelSelect
        goNext={this.incrementIndex}
        goBack={this.decrementIndex}
        selectLevel={this.selectLevel}
        selectedLevel={this.state.userSelections.level}
        valid={
          this.state.userSelections.level != null &&
          this.state.navigation.materialsReady
        }
        changeActiveMode={this.changeActiveMode}
        activeMode={this.state.navigation.activeMode}
        modalIsOpen={this.state.navigation.modalIsOpen}
        closeModal={() => this.closeModal()}
        confirmMaterials={() => this.confirmMaterials()}

        cachedModuleIds={this.state.userSelections.group && this.state.userSelections.group.cached_config_ids.module ? this.state.userSelections.group.cached_config_ids.module : []} 
      />,
      <ReviewScreen
        goBack={this.decrementIndex}
        materials={this.state.userSelections.materials}
        group={this.state.userSelections.group}
        level={this.state.userSelections.level}
        getPlaythroughId={this.getPlaythroughId}
        playthroughId={this.state.playthroughId}
      />
    ] 

    const playthroughLoadError = (
      <div>
        <h1>An error occured while trying to create the playthrough.</h1>
        <button onClick={() => this.getPlaythroughId()}>Retry</button>
      </div>
    );

    const views = {
      launcherSteps: launcherSteps[this.state.navigation.currentIndex],
      loadingPlaythrough: <LoadingMessage type="default" message="Loading game windows..." />,
      playthroughLoadError: playthroughLoadError,
      photoUpload: <Redirect to={urljoin('/photo_upload', this.state.playthroughId)} />
    }

    return (
      <div id="launcher-background">
        { views[this.state.activeView] }
      </div>
    )
  }
}
