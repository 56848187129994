import React from 'react'

import ContactInfo from './components/ContactInfo'
import SvcPingCheck from './components/SvcPingCheck'

export default class SupportHome extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        {/* <ContactInfo /> */}
        <SvcPingCheck />
      </div>
    )
  }
}