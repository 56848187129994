import { Howl } from 'howler';

export default class TimerTrack {
    constructor(options) {
        this.a = new Howl({...options.a, loop:true});
        this.b = new Howl ({...options.b, loop:true});
        this.hit = new Howl(options.hit);
    }

    play() {
        this.section = this.a;
        this.section.play();
    }

    pause() {
        if (this.section) this.section.pause();
    }

    resume() {
        if (this.section) this.section.play();
    }

    halfHit() {
        // this.a.stop();
        // this.hit.play(); 

        // this sounds a lot better than the hard cut, but how to get the correct volume rather than the 0.25 hard set?
        this.hit.play();
        this.a.fade(0.35, 0, 100);
    }

    playB() {
        this.section = this.b;
        this.section.play();
    }

    endHit() {
        // this.b.stop();
        // this.hit.play(); 

        this.hit.play();
        this.b.fade(0.25, 0, 100); 
    }

    stopAll() {
        this.a.stop();
        this.b.stop();
        this.hit.stop();
    }

}
