import React from 'react' 
import urljoin from 'url-join'
import FirebaseContext from 'auth/FirebaseContext';

import Dropdown from 'react-dropdown'
import EditBox from '../../misc_components/EditBox'

import RectangleButton from '../../ui_elements/rectangle/RectangleButton'

export default class MyProfile extends React.Component {
  static contextType = FirebaseContext;

  constructor(props) {
    super(props);
    this.state = {
      user: {},
      selectLocationActive: false,
      isEditingName: false
    }

    this.changeName = this.changeName.bind(this);
  }

  updateNewUser(field, value) {
    this.setState(prevState => ({
      user: {
        ...prevState.user,
        [field]: value
      }
    }));
  }

  async dropdownClick(event) {
    const data = {
      updates: {
        favorite_location_id: event.value,
        favorite_location_name: event.label
      }
    }

    await this.context.imagarenaApiClient.patch(urljoin(process.env.REACT_APP_ROLES_SVC_ENDPOINT, 'users'), data);
    this.setState({ isEditingLocation: false }, () => this.context.refresh());
  }

  async changeName(newName) {
    const data = {
      updates: {
        display_name: newName
      }
    }

    await this.context.imagarenaApiClient.patch(urljoin(process.env.REACT_APP_ROLES_SVC_ENDPOINT, 'users'), data);
    this.setState({ isEditingName: false }, () => this.context.refresh());
  }

  render() {
    
    const options = this.props.locations.map((location) => {
      return {
        value: location._id,
        label: location.name
      }
    });

    const displayName = (
      <div className="editable-profile-field">
        <h3>{this.context.rolesData.name}</h3>
        <RectangleButton text={'EDIT'} color={'blue'} size={'xs'} selectCallback={() => this.setState({ isEditingName: true })} />
      </div>
    )

    const displayLocation = (
      <div className="editable-profile-field">
        <h3>{this.context.rolesData.cached_data.favorite_location_name}</h3>
        <RectangleButton text={'EDIT'} color={'blue'} size={'xs'} selectCallback={() => this.setState({ isEditingLocation: true })} />
      </div>
    )

    const dropdown = (
      <div id="dropdown-container">
        <Dropdown options={options} onChange={(event) => this.dropdownClick(event)}  placeholder="Select a new favorite location" />
      </div>
    )
    const nameSection = this.state.isEditingName ? <EditBox cancel={() => this.setState({ isEditingName: false })} save={this.changeName} /> : displayName;
    const locationSection = this.state.isEditingLocation ? dropdown : displayLocation;

    // 1/3/20 CHECK: what's the best way to handle this?
    const rolesEnum = {
      0: 'Owner',
      1: 'Admin',
      2: 'Instructor'
    }

    return (
      <div className="section-container">

        <div className="my-profile-field">NAME</div>
        { nameSection }
    
        <div className="my-profile-field">EMAIL</div>
        <h3>{this.context.rolesData.email}</h3>

        <div className="my-profile-field">ACCOUNT TYPE</div>
        <h3>{ rolesEnum[this.context.rolesData.permission] }</h3>

        <div className="my-profile-field">FAVORITE LOCATION</div>
        { locationSection }

      </div>
    )
  }
}