import React from 'react';

import TimerTimeline from '../scripts/anime/TimerTimeline';
import ScreenText from '../../otaku/src/react_components/ScreenText';
import ChallengeContent from './shared_packets/ChallengeContent'

export default class TimerAnimation extends React.Component {
    constructor(props) {
        
        super(props);
        this.animation = new TimerTimeline({
            enableNextSlide: this.props.enableNextSlide,
            staticAudio: this.props.staticAudio,
            dynamicAudio: this.props.dynamicAudio,
            phrases: {
                beginning: this.props.flags.phrases.beginning,
                halfway: this.props.flags.phrases.halfway
            },
            
            hasPicture: this.props.flags.hasPicture,
            timeLimit: this.props.flags.timeLimit,
            bpm: this.props.flags.bpm,
            isMiniTimerDisplay: this.props.flags.isMiniTimerDisplay,
            containsMiniPreload: this.props.flags.containsMiniPreload,
            
            startClock: () => this.startClock(),
            timeElapsed: () => this.timeElapsed()
        });

        this.state = ({
            time: this.animation.displayTime()
        })

    }

    componentDidMount() {
        this.animation.animate();
    }

    componentWillUnmount() {
        this.animation.tearDown();
        clearInterval(this.clockId);
    }

    pause() {
        this.animation.pause();
        this.pauseClock();
    }

    resume() {
        this.animation.resume();
        if (this.clockId) this.startClock();
    }

    startClock() {
        this.start = Date.now();
        this.end = Date.now();
        this.clockId = setInterval(() => {
            this.end = Date.now();
            this.controlTimer();
        }, 10);
    }

    controlTimer() {
        this.animation.animateSeq();
        this.setState({time: this.animation.displayTime()});
        this.animation.controlTrack();
    }

    pauseClock() {
        this.snapshot = this.timeElapsed();
        clearInterval(this.clockId);
    }

    timeElapsed() {
        return this.snapshot ? (this.end - this.start) / 1000 + this.snapshot : (this.end - this.start) / 1000 ;
    }

    render() {

        const blackBackground = <div id="black-background" className="child" />;

        const clockBackground = this.props.dynamicImages.clockBackground ? <img id="clock-background-canvas" className="child canvas" src={this.props.dynamicImages.clockBackground} /> : '';

        const whiteBackground = <div id="white-background" className="child" />;

        const roundHeader = <img id="round-header" className="child" src={this.props.dynamicImages.roundNumber}/>;

        const miniPreload = (
            <div id="mini-preload-container" className="parent container">
                { whiteBackground }
                { roundHeader }
            </div>
        );

        const timerText = <ScreenText id="timer-text" text={this.state.time} />;

        const cameraWhite = <img id="camera-white" className="child" src={this.props.staticImages.cameraWhite} />;

        const halfwayGraphic = <img id="halfway-gif" className="child" src={this.props.staticImages.clock} />;

        const halfwayGroup = (
            <div id="halfway-group-container" className="parent container">
                { blackBackground }
                { halfwayGraphic }
            </div>
        )

        const panels = (
            <div id="panel-container" className="parent container">
                <div id="top-panel" className="child" />
                <div id="bottom-panel" className="child" />
            </div>
        );

        const createNumbers = () => {
            const numbers = []

            for (let i = 1; i <= 10; i++) {
                numbers.push(
                    <img key={i} id={`number-${i}`} className="child" src={this.props.staticImages[i]} />
                )
            }

            return numbers;
        }

        const shareBackground = this.props.dynamicImages.shareBackground ? <img id="share-background-canvas" className="child canvas" src={this.props.dynamicImages.shareBackground} /> : '';
        
        const shareGraphic = <img id="share-gif-canvas" className="child canvas" src={this.props.staticImages.share} />;
        
        const cameraBlack = <img id="camera-black" className="child" src={this.props.staticImages.cameraBlack} />;

        const endContainer = (
            <div id="end-container" className="parent container">
                { whiteBackground }
                { shareBackground }
                { shareGraphic }
                { cameraBlack }
            </div>
        );

        const foreground = this.props.dynamicImages.foreground ? <img id="foreground" className="child" src={this.props.dynamicImages.foreground} /> : '';
        
        const challengeContent = (
            <ChallengeContent
                challengeText={this.props.flags.challengeText}
                challengeImage={this.props.dynamicImages.challengeImage}
            />
        )

        const clockOnlyView = (
            <div id="clock-only-view-container" className="parent container">
                { challengeContent }
            </div> 
        )

        
    
        return (
            <div id="screen" className="parent">

                { blackBackground }

                { clockBackground }

                { this.props.flags.containsMiniPreload ? miniPreload : ''}

                { timerText }

                { clockOnlyView }

                { halfwayGroup }

                { cameraWhite }

                { panels }          

                { createNumbers() }

                { endContainer }

                { foreground }

                
                
            </div>
        );
    }
}
