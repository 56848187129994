import './css/style.css';
import './ui_elements/ui_element.css';

import React from 'react';
import urljoin from 'url-join';

import HTTPClient from './http/HTTPClient';
import { firebaseInit, firebaseLogOut } from './auth/firebaseHandlers';
import { initLogRocket, setLogRocketIdentity } from './logging/logRocketHandlers';

import LoadingMessage from './misc_components/LoadingMessage';
import UserWrapper from './routing/UserWrapper'

initLogRocket();

export default class App extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      authUser: null,
      rolesData: null,
      appLoaded: false,
      error: null
    };

    this.updateUser = this.updateUser.bind(this);
    this.refreshRolesData = this.refreshRolesData.bind(this);
    this.logOut = this.logOut.bind(this);
    this.setLoaded = this.setLoaded.bind(this);
    
    this.setError = this.setError.bind(this);
    this.clearError = this.clearError.bind(this);

    this.imagarenaApiClient = null;

    firebaseInit({
      updateCallback: this.updateUser,
      loadedCallback: this.setLoaded
    });
  }

  async updateUser(authUser) {
    if (!authUser) {
      this.imagarenaApiClient = null;
      return;
    }

    this.imagarenaApiClient = new HTTPClient({
      authUser: authUser,
      errorCallback: this.setError
    });

    await this.initUser(authUser);
  }

  async initUser(authUser) {
    const rolesData = await this.fetchRolesData();
    this.setState({authUser, rolesData}, () => setLogRocketIdentity(this.state.rolesData));
  }

  async refreshRolesData() {
    const rolesData = await this.fetchRolesData();
    await this.setState({ rolesData });
  }

  async fetchRolesData() {
    // 2/5/20 QUESTION: how to catch an error from this get?
    const rolesResponse = await this.imagarenaApiClient.get(urljoin(process.env.REACT_APP_ROLES_SVC_ENDPOINT, "users"));
    return rolesResponse.data.user;
  }

  setLoaded() {
    this.setState({appLoaded: true});
  }

  async logOut() {
    await firebaseLogOut();
    this.setState({authUser: null, rolesData: null});
  }

  setError(errorInfo) {
    this.setState({ error: errorInfo });
  }

  clearError() {
    this.setState({ error: null });
  }

  render() {
    if (!this.state.appLoaded) return <LoadingMessage type='default' message="Loading app..." /> 
    
    return (
      <UserWrapper 
        authUser={this.state.authUser} 
        rolesData={this.state.rolesData} 
        logOut={this.logOut} 
        imagarenaApiClient={this.imagarenaApiClient} 
        refresh={this.refreshRolesData}
        
        // 2/5/20 TODO: spin these off into their own context
        error={this.state.error}
        clearError={this.clearError}
      />
    );
  }
}

