import React from 'react'
import ScreenText from '../../../otaku/src/react_components/ScreenText';

export default function ChallengeContent(props) {
        
    const challengeText = props.challengeText ? <ScreenText id="challenge-text" text={props.challengeText} /> : '';
        
    const challengeImage = props.challengeImage ? <img id="challenge-image" className="child" src={props.challengeImage} /> : '';

    return (
        <div id="challenge-content-container" className="parent container">
            { challengeText }
            { challengeImage } 
        </div>
    )
}