import React from 'react'

import urljoin from 'url-join'
import FirebaseContext from 'auth/FirebaseContext';

import Locations from './components/Locations'
import Users from './components/Users'
import MyProfile from './components/MyProfile'

import 'react-dropdown/style.css'

import RectangleButton from '../ui_elements/rectangle/RectangleButton'
import LoadingMessage from '../misc_components/LoadingMessage';

import './profile.css'

export default class UserProfile extends React.Component {
  static contextType = FirebaseContext;

  constructor(props) {
    super(props);
    this.state = {
      locations: null,
      activeView: 'profile'
    }

    this.refreshFromDB = this.refreshFromDB.bind(this);
  }

  async componentDidMount() {
    this.refreshFromDB()
  }

  async refreshFromDB() {
    const response = await this.context.imagarenaApiClient.get(urljoin(process.env.REACT_APP_GROUP_MANAGER_SVC_ENDPOINT, 'organizations/', this.context.rolesData.org_id, 'locations')); 
    const locations = response.data.locations;
    this.setState({ locations });
  }

  render() {
    if (!this.state.locations) return <LoadingMessage type="default" message="Loading locations..." />

    const profile = (
      <div className="user-section">
        <RectangleButton selectCallback={() => this.setState({ activeView: 'profile' })} text={'Profile'} size={'md'} selected={this.state.activeView === 'profile'} />
        { this.state.activeView === 'profile' ? <MyProfile locations={this.state.locations}/> : '' }
      </div>
    )

    const users = (
      <div className="user-section">
        <RectangleButton selectCallback={() => this.setState({ activeView: 'users' })} text={'Users'} size={'md'} selected={this.state.activeView === 'users'} />
        { this.state.activeView === 'users' ? <Users locations={this.state.locations} /> : '' }
      </div>
    )
    
    const locations = (
      <div className="user-section">
        <RectangleButton selectCallback={() => this.setState({ activeView: 'locations' })} text={'Locations'} size={'md'} selected={this.state.activeView === 'locations'} />
        { this.state.activeView === 'locations' ? <Locations locations={this.state.locations} refresh={this.refreshFromDB} /> : '' }
      </div>
    )

    let screenContent;
    switch(this.context.rolesData.permission) {
      case 0:
        screenContent = (
          <div className="user-container">
            { profile }
            { users }
            { locations }
          </div>
        )
        break;
      
      case 1:
        screenContent = (
          <div className="user-container">
            { profile }
            { users }
          </div>
        )
        break;

      default:
        screenContent = (
          <div className="user-container">
            { profile }
          </div>
        )  
    }

    return (
      <div>
        { screenContent }
      </div>
    )
  }
}