import path from 'path';
import React from 'react';

import RectangleButton from '../../../ui_elements/rectangle/RectangleButton'

import audio from '../../../ui_elements/ui-audio.json'

import Arrow from "img/arrow.png"

export default class BackArrow extends React.Component {
  constructor(props) {
    super(props);
    this.audio = audio;
  }

  render() {
    return (
      <div onClick={this.props.goBack} className="back-arrow">
        <RectangleButton text={'BACK'} size={'md'} img={Arrow} color={'red'} reverse={true} flipImg={true}/>
      </div>
    );
  }
}
