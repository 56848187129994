import React from 'react'
import RectangleButton from '../../../ui_elements/rectangle/RectangleButton'

function LevelSlot(props) {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <RectangleButton selected={props.selected} text={props.level.display_info.name} selectCallback={() => props.selectLevel(props.level)} size={'sm'} />
      { props.novel ? <div className="new-badge">NEW</div> : '' }
    </div>
  )
}

export default function ModeSlot(props) {
  return (
    <div className="mode-container">
      <RectangleButton text={props.mode.display_info.name} size={'lg'} selectCallback={() => props.changeActiveMode()} selected={props.activeMode === props.mode.pack_uid} selectedStyle={'light'}/>
      { props.activeMode === props.mode.pack_uid ? props.mode.levels.map(level => <LevelSlot selected={props.selectedId === level.pack_uid} key={level._id} selectLevel={props.selectLevel} level={level} novel={!props.cachedModuleIds.includes(level.pack_uid)}/>) : ''}
    </div>
  )
}