import ScreenElement from './ScreenElement'

export default class ImageChild extends ScreenElement {
    constructor(options) {
        super(options);
        this.size = options.size !== undefined ? options.size : 25;

        // experimental feature
        this.endPosition = options.endPosition !== undefined ? options.endPosition : null;
    }

    height() {
        return this.size;
    }

    width() {
        const aspect = 16/9;
        return this.height() * 1 / aspect;
    }

    setX(x) {
        return `${ 100 / this.width() * x }%`;
    }

    setY(y) {
        return `${ -(100 / this.height() * y) }%`; // added the '-' negative to flip the y coordinates.  why was y-negative 'up' in the first place?
    }

    getStartingPosition() {
        return  {
            width: `${this.width()}%`,
            height: `${this.height()}%`,
            translateX: this.setX(this.x),
            translateY: this.setY(this.y),
            scale: this.scale,
            opacity: this.opacity,
            rotateZ: this.rotation
        }
    }
}