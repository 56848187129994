import './css/firewall-test.css'

import React from 'react'
import urljoin from 'url-join'
import FirebaseContext from 'auth/FirebaseContext';

import LoadingMessage from '../../misc_components/LoadingMessage'
import RectangleButton from '../../ui_elements/rectangle/RectangleButton';
import SuccessIcon from '../../img/success.png'
import FailIcon from '../../img/exclamation.png'

export default class SvcPingCheck extends React.Component {
  static contextType = FirebaseContext;

  constructor(props) {
    super(props);

    this.state = {
      failedSvcUrls: [],
      pendingResponse: false,
      pingComplete: false
    }

    this.svcUrls = [
      process.env.REACT_APP_ROLES_SVC_ENDPOINT,
      process.env.REACT_APP_GROUP_MANAGER_SVC_ENDPOINT,
      process.env.REACT_APP_SEQUENCE_SVC_ENDPOINT,
      process.env.REACT_APP_MATERIAL_SVC_ENDPOINT,
      process.env.REACT_APP_MODE_SVC_ENDPOINT
    ]

    this.baseDomains = [
      'imagarena.com',
      `${process.env.REACT_APP_ENV_NAME}.imagarena.com`
    ]
  }

  async pingSvcs() {
    this.setState({ pendingResponse: true });

    await Promise.all(this.svcUrls.map(async (svcUrl) => {
      try {
        await this.context.imagarenaApiClient.get(urljoin(svcUrl, 'ping'), { globalErrorDisabled: true }); 
      } catch(error) {
        var joined = this.state.failedSvcUrls.concat(svcUrl);
        this.setState({ failedSvcUrls: joined });
      }
    }));

    this.setState({ pingComplete: true });
  }

  render() {
    const formatUrl = (url) => {
      const noHTTP = url.replace("https://", "");
      return noHTTP.replace("/ping", "");
    }

    const displayDomains = this.svcUrls.map((svcUrl, i) => {
      const formattedUrl = formatUrl(svcUrl);
      return <li key={svcUrl}>{ formattedUrl }</li>
    });

    const baseDomains = this.baseDomains.map((domain, i) => {
      const formattedUrl = formatUrl(domain); 
      return <li key={domain}>{ formattedUrl }</li>
    });

    const successMessage = (
      <div className="message">
        <div className="icon-text-message">
          <img className="message-icon" src={SuccessIcon} />
          <span id="success-text">Compatible</span>
        </div>
        <p>Your organization's network is compatible with this app.</p>
      </div>
    );

    const failMessage = (
      <div className="message">
        <div className="icon-text-message">
          <img className="message-icon" src={FailIcon} />
          <span id="fail-text">There is an issue with your organization's firewall.</span>
        </div>
        <div id="instructions">
          <p>If your organization is using a firewall, please allow all of the imagarena.com subdomains (<span style={{fontWeight: 'bold'}}>*.imagarena.com</span>).</p>
          <p>If you cannot do that, please add the following domains to the whitelist:</p>
          <ul>
            { [...baseDomains, ...displayDomains] }
          </ul>
        </div>
      </div>
    );

    const pingButton = <RectangleButton text={'Start Test'} color={'blue'} selectCallback={() => this.pingSvcs()} size={'sm'} />

    return (
      <div id="firewall-test">
        <h1>Firewall Compatibility Test</h1>
        { !this.state.pingComplete ? <p style={{'marginTop': '0'}}>Confirm you are connected to the internet, then run the test to see if your organization's firewall is compatible with this site.</p> : '' }
        { !this.state.pingComplete && !this.state.pendingResponse ? pingButton : '' }
        { !this.state.pingComplete && this.state.pendingResponse ? <LoadingMessage nested={true} message="Checking firewall settings..." /> : '' }
        { this.state.pingComplete && this.state.failedSvcUrls.length ? failMessage : '' }
        { this.state.pingComplete && !this.state.failedSvcUrls.length ? successMessage : '' }
      </div>
      
    )
  }
}

