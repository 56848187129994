import './css/global-error-modal.css'

import React from 'react'
import Modal from 'react-modal';
import { Link } from "react-router-dom";

Modal.setAppElement('#root');

const errorModalStyle = {
  content : {
    top : '50%',
    left : '50%',
    right : 'auto',
    bottom : 'auto',
    marginRight : '-50%',
    transform : 'translate(-50%, -50%)'
  }
};

function ErrorDetails(props) {
  return (
    <div>
      <h5><span className="error-detail">Code</span> {props.errorDetails.statusCode}</h5>
      <h5><span className="error-detail">Message</span> {props.errorDetails.message}</h5>
      <h5><span className="error-detail">URL</span> {props.errorDetails.url}</h5>
    </div>
  )
}

export default function GlobalErrorModal(props) {
  const supportPageMessage = (
      <Link to={'/support'} onClick={() => props.clearError()}>Visit the support page for more details.</Link>
  );

  // 2/6/20 CHECK: what to return here?
  if (!props.error) return <div />
  
  return (
    <Modal isOpen={props.modalIsOpen} style={errorModalStyle} overlayClassName="overlay">
      <h1>{props.error.name}</h1>
      <h3>Check your internet connection and reload this page.</h3>

      <hr />
      <p>{ props.error.message }</p>
      
      { props.error.showSupportLink ? supportPageMessage : '' }
      { props.error.details ? <ErrorDetails errorDetails={props.error.details} /> : '' }
    </Modal>
  )
}