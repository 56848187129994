import anime from 'animejs';
import AudioPlayer from '../audio_utils/AudioPlayer';
import generateCollection from '../../../otaku/src/scripts/generateCollection';
import pluralCheck from '../audio_utils/pluralCheck';

import collection from 'animations/screen_collections/preloadCollection.json';

export default class PreloadTimeline {
    constructor(options) {
        this.audio = new AudioPlayer();
        this.activeAnimations = {}
        this.enableNextSlide = options.enableNextSlide;
        
        this.staticAudio = options.staticAudio;
        this.dynamicAudio = options.dynamicAudio;
        
        this.collection = generateCollection(collection);
        this.elements = this.collection.elements;
        
        this.playerSelect = options.playerSelect;
        this.special = options.special;
        this.types = options.types;
        this.colors = options.colors;
        this.jumbo = options.jumbo;
        
        // ??
        this.popUp = options.popUp 
    }

    tearDown() {
        // REVIEW: @sh0shinsha why is this null and the other way of clearing delete?
        // Deleting local variable in strict mode
        Object.values(this.activeAnimations).forEach((timeline) => {
            timeline.pause();
        });
        
        this.audio.killAllAudio();
        this.audio = null;
    }

    timelineComplete(timeline) {
            
    }

    pause() {
        Object.values(this.activeAnimations).forEach((timeline) => {
            timeline.pause();
        });
        this.audio.pause();
    }

    resume() {
        Object.values(this.activeAnimations).forEach((timeline) => {
            timeline.play();
        });
        this.audio.resume();
    }

    animate() {
        this.collection.setAllElements(anime);

        this.mainTimeline = anime.timeline({});
        this.activeAnimations.mainTimeline = this.mainTimeline;

        this.insertSectionA(this.jumbo, this.popUp);
        this.insertSectionB(this.special);
        this.insertSectionC(this.special);

        this.startIndependentLoops();
    }

    startIndependentLoops() {
        
        const newIcon = anime({
            targets: this.elements.newIcon.tag,
            keyframes: [
                { scale: 1.5 },
                { scale: 1 }
            ],
            duration: 500,
            easing: 'linear',
            loop: true
        });
        this.activeAnimations.newIcon = newIcon;

        const foregroud = anime({
            targets: this.elements.foreground.tag,
            keyframes: [
                { translateX: this.elements.foreground.setX(5) },
                { translateX: this.elements.foreground.setX(-5) }
            ],
            duration: 5000,
            easing: 'linear',
            loop: true
        });
        this.activeAnimations.foregroud = foregroud;
    }

    insertSectionA(hasJumbo, hasPopUp) {

        const roundReveal = () => {
            this.mainTimeline
            .add({
                targets: this.elements.roundNumber.tag,
                keyframes: [
                    {
                        scale: 1,
                        duration: 1000,
                        easing: 'easeOutElastic(1, .5)',
                        complete: () => { this.audio.playClip(this.dynamicAudio.round, this.dynamicAudio.roundNum) }
                    },
                    {
                        scale: 0,
                        duration: 200,
                        easing: 'easeOutQuad',
                        delay: 1000
                    }
                ]
            });
        }

        const jumboReveal = () => {
            this.mainTimeline
            .add({
                targets: this.elements.jumboHeader.tag,
                keyframes: [
                    {
                        scale: 1,
                        duration: 1000,
                        easing: 'easeOutElastic(1, .5)',
                    },
                    {
                        opacity: 0,
                        duration: 1000,
                        easing: 'linear',
                        delay: 2000
                    }
                ],
                changeBegin: () => {
                    this.audio.playClip(this.staticAudio.jumboHit);
                    setTimeout(() => this.audio.playClip(this.dynamicAudio.jumbo), 1000);
                }
            })
        }

        const popUpReveal = () => {
            this.mainTimeline
            .add({
                targets: this.elements.popUpContainer.tag,
                keyframes: [
                    {
                        scale: 1.4,
                        duration: 1000,
                        easing: 'easeOutElastic(1, .5)',
                    },
                    {
                        translateX: this.elements.popUpContainer.setX(39.5),
                        translateY: this.elements.popUpContainer.setY(31),
                        scale: 0.75,
                        duration: 400,
                        easing: 'easeOutQuint',
                        delay: 1000
                    }
                ],
                changeBegin: () => this.audio.playClip(this.staticAudio.popUpHit)
            })
        }

        roundReveal();
        if (hasPopUp) popUpReveal();
        if (hasJumbo) jumboReveal();
    }

    insertSectionB(hasSpecial) {

        const numbersReveal = () => {
            this.mainTimeline
            .add({
                targets: this.elements.typesContainer.tag,
                translateX: this.elements.typesContainer.setX(0),
                duration: 300,
                easing: 'easeOutElastic(1, .8)',
                delay: 1000,
                changeBegin: () => {
                    this.audio.playClip(this.staticAudio.typesHit);
                    // put the plural check func in the preload Slide class
                    setTimeout( () => this.audio.playClip(this.dynamicAudio.typesNum, pluralCheck(this.types, this.dynamicAudio.type, this.dynamicAudio.types)), 1000);
                }
            })
            .add({
                targets: this.elements.colorsContainer.tag,
                translateX: this.elements.colorsContainer.setX(0),
                duration: 300,
                easing: 'easeOutElastic(1, .8)',
                delay: 2000,
                changeBegin: () => {
                    this.audio.playClip(this.staticAudio.colorsHit);
                    setTimeout( () => this.audio.playClip(this.dynamicAudio.colorsNum, pluralCheck(this.colors, this.dynamicAudio.color, this.dynamicAudio.colors)), 1000);
                }
            })
        }

        const standardPostNumbers = () => {
            this.mainTimeline
            .add({
                targets: this.elements.typesAndColorsContainer.tag,
                translateX: this.elements.typesAndColorsContainer.setX(-40),
                translateY: this.elements.typesAndColorsContainer.setY(31),
                scale: .7,
                duration: 400,
                easing: 'easeOutQuint',
                delay: 2000
            });
        }
 
        const specialPostNumbers = () => {
            this.mainTimeline
            .add({
                targets: this.elements.plusSign.tag,
                scale: 1,
                duration: 300,
                delay: 2000,
                changeBegin: () => this.audio.playClip(this.staticAudio.plusSign)
            })
            .add({
                targets: this.elements.typesAndColorsContainer.tag,
                translateX: this.elements.typesAndColorsContainer.setX(20),
                duration: 400,
                easing: 'easeOutQuint',
                delay: 2000
            }, '-=2300')
            .add({
                targets: this.elements.specialContainer.tag,
                scale: 1,
                duration: 500,
                changeBegin: () => {
                    this.audio.playClip(this.staticAudio.plusReveal);
                    setTimeout( () => this.audio.playClip(this.dynamicAudio.special), 1000);
                }
            })
            .add({
                targets: this.elements.plusSign.tag,
                scale: 0,
                duration: 300,
                delay: 1000
            })
            .add({
                targets: this.elements.specialCard.tag,
                scale: 0,
                duration: 300
            }, '-=300')
            .add({
                targets: this.elements.typesAndColorsContainer.tag,
                translateX: this.elements.typesAndColorsContainer.setX(10),
                scale: .6,
                duration: 1000
            })
            .add({
                targets: this.elements.specialContainer.tag,
                translateX: this.elements.specialContainer.setX(-15),
                duration: 1000
            }, '-=1000')
            .add({
                targets: this.elements.comboContainer.tag,
                translateX: this.elements.comboContainer.setX(-30),
                translateY: this.elements.typesAndColorsContainer.setY(30),
                scale: .6,
                duration: 400,
                easing: 'easeOutQuint'
            }, '-=1000')
        }

        numbersReveal();
        hasSpecial ? specialPostNumbers() : standardPostNumbers(); 

    }

    insertSectionC(hasSpecial) {
        const REVEAL_TIMING = hasSpecial ? '-=1000' : '-=400';

        this.mainTimeline
        .add({
            targets: this.elements[`${this.playerSelect}Icon`].tag,
            translateX: this.elements[`${this.playerSelect}Icon`].setX(this.elements[`${this.playerSelect}Icon`].endPosition.x),
            translateY: this.elements[`${this.playerSelect}Icon`].setY(-34),
            duration: 400,
            easing: 'easeOutQuint'
        }, REVEAL_TIMING)
        .add({
            targets: this.elements.boxAndNewIconContainer.tag,
            scale: 1,
            duration: 1500,
            easing: 'easeOutElastic(1, .5)',
            changeBegin: () => {
                this.audio.playClip(this.staticAudio.containerHit);
                setTimeout( () => this.audio.playClip(this.dynamicAudio.box), 1000);
            }
        }, REVEAL_TIMING)
        .add({
            targets: this.elements.handA.tag,
            translateY: this.elements.handA.setY(-15),
            duration: 300,
            easing: 'easeOutQuad',
            delay: 2000,
            changeBegin: () => { 
                this.audio.playClip(this.dynamicAudio.loading); 
            }
        })
        .add({
            targets: this.elements.boxAndHandArea.tag,
            translateY: this.elements.boxAndHandArea.setY(-75),
            duration: 750,
            easing: 'linear',
            delay: 1000,
            complete: () => { 
                this.startLoadingLoop();
            }
        });
    }

    startLoadingLoop() {
        delete this.activeAnimations.mainTimeline; 
        // 1/2/20 CHECK: this is to avoid upcomingSlide loading interfering with media playback on this slide...
        setTimeout( () => this.enableNextSlide(), 1000);  

        this.audio.playLoop(this.dynamicAudio.loadingMusic);

        const boxAnimation = {
            targets: this.elements.boxAndNewIconContainer.tag,
            scale: 1,
            duration: 1500,
            easing: 'easeOutElastic(1, .5)',
            delay: 500
        }

        const handAnimation = (hand) => {
            return {
                targets: this.elements[hand].tag,
                translateY: this.elements[hand].setY(-15),
                duration: 400,
                easing: 'linear',
                delay: 250,
            }
        }

        const containerAnimation = {
            targets: this.elements.boxAndHandArea.tag,
            translateY: this.elements.boxAndHandArea.setY(-75),
            duration: 750,
            easing: 'linear',
            delay: 1000
        }

        const hands = ['handB', 'handA', 'handB', 'crazyHand'];

        let counter = 0;

        const incrementCounter = () => {
            if (counter < hands.length -1 ) {
                return counter += 1;
            } else {
                counter = 0;
            }
        }

        const playLoopSegment = () => {
            const loopTimeline = anime.timeline({
                complete: () => { 
                    if (this.activeAnimations.handLoop) {
                        return playLoopSegment()
                    }  
                    
                    return null; }
            });
            
            this.activeAnimations.handLoop = loopTimeline;

            anime.set(this.elements.boxAndHandArea.tag, { translateY: this.elements.boxAndHandArea.setY(this.elements.boxAndHandArea.y) });
            anime.set(this.elements.boxAndNewIconContainer.tag, { scale: this.elements.boxAndNewIconContainer.scale });
            anime.set(this.elements.handA.tag, { translateY: this.elements.handA.setY(this.elements.handA.y) });
            anime.set(this.elements.handB.tag, { translateY: this.elements.handB.setY(this.elements.handB.y) });
            anime.set(this.elements.crazyHand.tag, { translateY: this.elements.handB.setY(this.elements.handB.y) });

            incrementCounter();

            loopTimeline
            .add(boxAnimation)
            .add(handAnimation(hands[counter]))
            .add(containerAnimation);
        }

        playLoopSegment(); // this is to init loop playback
    }
}
