import React from 'react'
import urljoin from 'url-join'
import FirebaseContext from 'auth/FirebaseContext';

import Player from './Player'
import Sequence from '../scripts/Sequence'

import LoadingMessage from '../../misc_components/LoadingMessage';
import RectangleButton from '../../ui_elements/rectangle/RectangleButton';
import DragAnimation from '../../img/drag_tab.gif';

import PlayIcon from '../../img/play.png'
import './css/player_loader.css'

const SEQUENCE_SVC_ENDPOINT = urljoin(process.env.REACT_APP_SEQUENCE_SVC_ENDPOINT, 'sequence/');


export default class PlayerLoader extends React.Component {
  static contextType = FirebaseContext;

  constructor(props) {
    super(props);
    this.state = {
        sequence: null,
        userConfirmation: false
    }

    this.playthroughId = this.props.match.params.playthroughId;
    this.playerWindow = React.createRef();
  }

  async componentDidMount() {
    const encodedSequence = await this.getEncodedSequence();
    const sequence = new Sequence(encodedSequence);
    sequence.decodeSequence();
    this.setState({ sequence });
  }

  async getEncodedSequence() {
    const response = await this.context.imagarenaApiClient.get(urljoin(SEQUENCE_SVC_ENDPOINT, this.playthroughId));
    return response.data.sequence;
  }

  handleConfirmation() {
    this.setState({ userConfirmation: true }, () => this.playerWindow.current.requestFullscreen());
  }

  render() {
    const confirmationScreen = (
      <div id="confirmation-box">
        <img id="drag-gif" src={DragAnimation} />
        <h4>Before starting the Level, drag this tab onto your projector / TV.</h4>
        <RectangleButton selectCallback={() => this.handleConfirmation()} size="lg" text="Start Level" color="blue" img={PlayIcon} />
      </div>
    );

    if (!this.state.sequence) return <LoadingMessage type="default" message="Loading level..." />;
    if (!this.state.userConfirmation) return confirmationScreen;

    console.log(this.state.sequence)
    
    return (
      <div ref={this.playerWindow}>
        <Player sequence={this.state.sequence} playthrough={this.playthroughId} />
      </div>
    )
  }
}