import React from 'react';
import urljoin from 'url-join';
import FirebaseContext from 'auth/FirebaseContext'

import MaterialModal from './subcomponents/MaterialModal'
import ModeSlot from './subcomponents/ModeSlot'
import BackArrow from './subcomponents/BackArrow';
import NextArrow from './subcomponents/NextArrow';
import ReactTooltip from 'react-tooltip'
import RectangleButton from '../../ui_elements/rectangle/RectangleButton'

import LoadingMessage from '../../misc_components/LoadingMessage';
import Info from "img/info.png"

const MODE_SVC = process.env.REACT_APP_MODE_SVC_ENDPOINT;

export default class LevelSelect extends React.Component {
  static contextType = FirebaseContext;

  constructor(props) {
    super(props);
    this.state = { modes: null }

    this.noModule = {
      pack_uid: "",
      display_info: {
        name: "Basic"
      },
      lessons: [],
      factoryMaterials: [],
      mode: {}
    }
  }

  async componentDidMount() {
    const data = await this.fetchMountData();
    this.setState({ modes: data });
  }

  async fetchMountData() {
    const modeResponse = await this.context.imagarenaApiClient.get(urljoin(MODE_SVC, 'modes/'));
    const modes = modeResponse.data.modes;
    console.log(modes);

    const populatedModes = [];
    for await (const mode of modes) {
      const levelsResponse = await this.context.imagarenaApiClient.get(urljoin(MODE_SVC, 'modes', mode.pack_uid, 'modules'));
      const levels = levelsResponse.data.modules;
      populatedModes.push({ ...mode, levels })
    }

    return populatedModes;
  }

  async selectLevel(level) {
    if (!level.pack_uid) {
      this.changeActiveMode(null);
      this.props.selectLevel(level);
      return;
    }

    // const data = await this.fetchLevelData(level);
    // const hasRequiredLessons = [...data.lessons, ...data.factoryMaterials].some(material => material.isRequired);
    // const populatedLevel = {...level, lessons: data.lessons, factoryMaterials: data.factoryMaterials, mode: data.mode, hasRequiredLessons };

    const populatedLevel = {...level, mode: level.pack_type}
    this.props.selectLevel(populatedLevel);
  }

  async fetchLevelData(level) {
    const lessonsPromise = this.context.imagarenaApiClient.get(urljoin(MODE_SVC, 'modules/', level.id, '/activities'));
    const factoryMaterialsPromise = this.context.imagarenaApiClient.get(urljoin(MODE_SVC, 'modules/', level.id, 'factory/'));
    const modePromise = this.context.imagarenaApiClient.get(urljoin(MODE_SVC, 'modes/', level.modeId));
    const responses = await Promise.all([lessonsPromise, factoryMaterialsPromise, modePromise]);
    const [lessonsResponse, factoryMaterialsResponse, modeResponse] = responses;

    return {
      lessons: lessonsResponse.data.activities,
      factoryMaterials: factoryMaterialsResponse.data.materials,
      mode: modeResponse.data.mode
    }
  }

  changeActiveMode(mode) {
    this.props.selectLevel(null);
    this.props.changeActiveMode(mode);
  }

  render() {
    if (!this.state.modes) return <LoadingMessage type="default" message="Loading modules..." />

    const selectedId = this.props.selectedLevel ? this.props.selectedLevel.pack_uid : null;

    const tipText = "Choose the Mode & Module that you’d like for this Level."

    const allModes = (
      this.state.modes.map(mode => <ModeSlot
        key={mode._id}
        changeActiveMode={() => this.props.changeActiveMode(mode.pack_uid)}
        activeMode={this.props.activeMode}
        selectedId={selectedId}
        selectLevel={(level) => this.selectLevel(level)}
        mode={mode}
        cachedModuleIds={this.props.cachedModuleIds}
      />)
    )

    const defaultMode = (
      <div className="mode-container">
        <RectangleButton text={'Basic'} size={'lg'} selected={selectedId === this.noModule.pack_uid} selectCallback={() => this.selectLevel(this.noModule)} />
      </div>
    )

    return (
      <div className="launcher-container">
        <BackArrow goBack={this.props.goBack} />

        {/* <MaterialModal
          modalIsOpen={this.props.modalIsOpen}
          selectedLevel={this.props.selectedLevel}
          confirmMaterials={this.props.confirmMaterials}
          changeActiveMode={this.props.changeActiveMode}
          closeModal={this.props.closeModal}
        /> */}

        <div className="title-section">
          <ReactTooltip id="tip" className="tip">{tipText}</ReactTooltip>
          <div className="title">Select Mode</div>
          <img src={Info} data-tip data-for="tip"/>
        </div>

        <div className="mode-wrapper">
          { defaultMode }
          { allModes }
        </div>

        { this.props.valid ? <NextArrow goNext={this.props.goNext} /> : '' }
      </div>
    )
  }
}
