import React from 'react';

import ClearTimeline from '../scripts/anime/ClearTimeline';
import Slideshow from './shared_animations/Slideshow'
import ScreenText from '../../otaku/src/react_components/ScreenText';

export default class ClearAnimation extends React.Component {
    constructor(props) {
        super(props);
        this.animation = new ClearTimeline({
            enableNextSlide: this.props.enableNextSlide,
            dynamicAudio: this.props.dynamicAudio,
            staticAudio: this.props.staticAudio
        });
    }

    componentDidMount() {
        this.animation.animate();
    }

    componentWillUnmount() {
        this.animation.tearDown();
    }

    pause() {
        this.animation.pause();
    }

    resume() {
        this.animation.resume();
    }

    render() {

        const whiteBackground = <div id="white-background" className="child" />;

        const blackBackground = <div id="black-background" className="child" />;

        const panels = (
            <div id="panel-container" className="parent container">
                <div id="top-panel" className="child" />
                <div id="bottom-panel" className="child" />
            </div>
        );

        const clearIcon = (
            <div id="clear-icon-container" className="parent container">
                <img id="clear-icon" className="child" src={this.props.staticImages.clearIcon} />
            </div>
        );

        const slideshow = (
            <div className="parent container">
                <Slideshow
                    photos={this.props.slideshowData.photos}
                    showPhotoTitle={true}
                    sfxFiles={this.props.sfxFiles}
                />
                <div className="parent container">
                    <div id="bottom-bar" className="child" />
                    <ScreenText id="group-name-text" text={this.props.slideshowData.group.name} />
                    {/* <ScreenText id="date-text" text={this.props.slideshowData.photoPlaythrough.start.toLocaleDateString()} /> */}
                    {/* <ScreenText id="date-text" text={this.props.slideshowData.photoPlaythrough.start} /> */}
                </div>
            </div>
            
        )

        const megaClear = <img className="child canvas" src={this.props.staticImages.megaClear} />
        const tutorial = (
            <div className="parent container">
                { blackBackground }
                <img id="tutorial-gif" className="child" src={this.props.dynamicImages.box} />
            </div>
        )   

        const getBackground = () => {
            if (this.props.flags.isMegaClear) return megaClear;
            if (this.props.flags.isTutorial) return tutorial;
            
            return slideshow;
        }

        return (
            <div id="screen" className="parent">

                { getBackground() }

                { whiteBackground }

                { panels }

                { clearIcon }

                

            </div>
        );
    }
}
