import React from 'react';

export default class MapCell extends React.Component {
    render() {

        const types = (
            <div className="parent container types-container-group">
                <img className="child combo-icon-group" src={this.props.staticImages.typesIcon} />
                <img className="child combo-number-group" src={this.props.dynamicImages.types} />
            </div>
        );

        const colors = (
            <div className="parent container colors-container-group">
                <img className="child combo-icon-group" src={this.props.staticImages.colorsIcon} />
                <img className="child combo-number-group" src={this.props.dynamicImages.colors} />
            </div>
        );

        const typesAndColors = (
            <div className={`parent container combo-container-group ${this.props.special ? 'hidden-element' : ''}`}>
                { types }
                { colors}
            </div>
        ); 
            
        const specialIcon = <img  className={`child special-icon-group ${this.props.special ? '' : 'hidden-element'}`} src={this.props.dynamicImages.specialIcon} />;

        const shellBackground = <img className='child shell-group' src={this.props.staticImages.cellShell} />;
        
        const specialStamp = <img className={`child cell-stamp-group ${this.props.special ? '' : 'hidden-element'}`} src={this.props.staticImages.special} />; 
        
        const jumboStamp =  <img className={`child cell-stamp-group ${this.props.isJumbo ? '' : 'hidden-element'}`} src={this.props.staticImages.jumbo} />;
        
        const newModeStamp =  <img className={`child cell-stamp-group ${this.props.isNewMode ? '' : 'hidden-element'}`} src={this.props.staticImages.newMode} />; 

        const shell = (
            <div id={this.props.cellId === this.props.roundNum ? 'active-shell' : ''} className={`parent container ${this.props.roundNum > this.props.cellId ? 'hidden-element' : ''}`}>
                { shellBackground }
                {/* { specialStamp } */}
                { jumboStamp }
                { newModeStamp }
            </div>
        );

        const playIcon = <img id={this.props.cellId === this.props.roundNum + 1 ? 'active-play' : ''} className="child play-group" src={this.props.staticImages.play} />;

        return (
            <div id={`cell-${this.props.cellId}-container`} className="parent container">
            
                { this.props.dynamicImages.modeImage ? '' : typesAndColors }

                { this.props.dynamicImages.modeImage ? '' : specialIcon } 

                { this.props.dynamicImages.modeImage ? <img className={`child ${this.props.isFill ? 'fill-mode-image-group' : 'default-mode-image-group'}`} src={this.props.dynamicImages.modeImage} /> : '' }

                { shell }

                { playIcon }

            </div>
        );
    }
}