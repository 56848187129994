import React from 'react';
import SlideshowAnimation from '../../scripts/anime/SlideshowAnimation';

// See old slideshow.jsx for usage:
// Keep track of index and array in Clear class, and then pass photo object down into PhotoDisplay
function PhotoDisplay(props) {
    const photoTitle = (
        <div className="text-hack-container">
            <div className="child title-text">{ props.photo.title || "Untitled" }</div>
        </div>
    );

    return (
        <div className="parent container">
            <img className="child canvas" src={props.photo.url} />
            { props.showPhotoTitle ? photoTitle : ''}
        </div>
    )
}

export default class Slideshow extends React.Component {
    constructor(props) {
        super(props);
        this.animation = new SlideshowAnimation({
            sfxFiles: this.props.sfxFiles
        });
        this.state = {
            photoIndex: 0
        }
    }

    componentDidMount() {
        this.animation.animate();
        this.startPhotoIndexCycle();
    }

    componentWillUnmount() {
        clearInterval(this.intervalId);
    }

    startPhotoIndexCycle() {
        const ms = 3000;
        this.intervalId = setInterval(() => {
            this.setState(
                { photoIndex: this.state.photoIndex === this.props.photos.length - 1 ? 0 : this.state.photoIndex += 1 },
                // this.props.photoTransition ? this.animation.photoTransition() : ''
            );
        }, ms);
    }

    render() {
        

        return (
            <div id="screen" className="parent">
                <PhotoDisplay
                    photo={this.props.photos[this.state.photoIndex]}
                    showPhotoTitle={this.props.showPhotoTitle}
                />
            </div>
        );
    }
}
