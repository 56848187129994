import anime from 'animejs'
import AudioPlayer from '../audio_utils/AudioPlayer';
import generateCollection from '../../../otaku/src/scripts/generateCollection'

import collection from 'animations/screen_collections/presentationCollection.json';

export default class PresentationTimeline {
    constructor(options) {
        this.audio = new AudioPlayer();
        this.timeline = anime.timeline({});
        this.enableNextSlide = options.enableNextSlide;

        this.collection = generateCollection(collection);
        this.elements = this.collection.elements;
    }

    tearDown() {
        this.audio.killAllAudio();
        this.audio = null;
    }

    animate() {
        this.collection.setAllElements(anime);
        // eventually have this tie in for when all of the pictures have been seen, right now this is hack because it was getting lost in the 
        // execution stack somehow...
        setTimeout(() => this.enableNextSlide(), 1000);
    }
}
