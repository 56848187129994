import axios from 'axios';

export default class HTTPClient {
  constructor(options) {
    this.authUser = options.authUser;
    this.errorCallback = options.errorCallback;

    this.axiosClient = axios.create({});

    this.axiosClient.interceptors.request.use(async (config) => {
      const jwt = await this.authUser.getIdToken();
      config.headers.Authorization = `Bearer ${jwt}`;
      config.headers['x-firebase-jwt'] = jwt;
      return config;
    });

    this.axiosClient.interceptors.response.use(
      response => this.handleSuccess(response),
      error => this.catchError(error)
    );
  }

  handleSuccess(response) {
    return response;
  }

  catchError(error) {
    if (error.config.globalErrorDisabled) {
      return Promise.reject({ ...error });
    }

    if (error.response) {
      this.handleServiceError(error);
    } else {
      this.handleNetworkError();
    }

    return Promise.reject({ ...error });
  }

  handleNetworkError() {
    const errorInfo = {
      name: 'Network Error',
      message: 'If the problem persists, it may be due to an issue with your organization\'s firewall.',
      showSupportLink: true
    }

    this.errorCallback(errorInfo);
  }

  handleServiceError(error) {
    const errorInfo = {
      name: 'Service Error',
      message: '',
      details: {
        url: error.response.config.url,
        statusCode: error.response.status,
        message: error.response.data.message
      }
    }

    this.errorCallback(errorInfo);
  }
  

  get(url, config) {
    return this.axiosClient.get(url, config)
  }

  post(url, data) {
    return this.axiosClient.post(url, data)
  }

  patch(url, data) {
    return this.axiosClient.patch(url, data)
  }
}
    