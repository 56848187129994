import React from 'react';
import FirebaseContext from 'auth/FirebaseContext';
import RectangleButton from '../ui_elements/rectangle/RectangleButton'

export default class EditBox extends React.Component {
  static contextType = FirebaseContext;

  constructor(props) {
    super(props);
    this.state = {
      text: this.props.text,
      exceededLength: false
    };
  }

  setText(event) {
    if (event.target.value.length > 35 ) {
      this.setState({ exceededLength: true });
      event.target.value.slice(0, 35);
      return;
    }

    this.setState({ text: event.target.value });
  }

  render () {
    return (
      <div>
        <div className="photo-edit-container">
        <input onChange={(event) => this.setText(event)} value={this.state.text} />
        <RectangleButton selectCallback={() => this.props.save(this.state.text)} clickSFX={'positive'} text={'SAVE'} color={'blue'} size={'xs'} />
        <RectangleButton selectCallback={() => this.props.cancel()} clickSFX={'negative'} text={'CANCEL'} size={'xs'} />
      </div>
      { this.state.exceededLength ? <h5 className="character-warning photo-warning">This field is limited to 35 characters</h5> : '' }
      </div>
      
    )
  }
}
