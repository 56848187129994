import anime from 'animejs';
import MusicPlaylist from '../audio_utils/MusicPlaylist'

import generateCollection from '../../../otaku/src/scripts/generateCollection';
import collection from '../..//screen_collections/titleCollection.json'

export default class TitleTimeline {
    constructor(options) {
        this.audio = new MusicPlaylist(options.allMusic);

        this.enableNextSlide = options.enableNextSlide;
        this.isEndTitle = options.isEndTitle;

        this.collection = generateCollection(collection);
        this.elements = this.collection.elements;
    }

    setElements() {
        this.collection.setAllElements(anime);
    }

    animate() {
        this.audio.startPlaylist();
        if (!this.isEndTitle) setTimeout(() => this.enableNextSlide(), 1000);
    }

    pause() {
        this.audio.pausePlaylist();
    }

    resume() {
        this.audio.resumePlaylist();
    }

    tearDown() {
        this.audio.stopPlaylist();
    }
}