import anime from 'animejs'
import AudioPlayer from '../audio_utils/AudioPlayer';
import generateCollection from '../../../otaku/src/scripts/generateCollection'
import collection from 'animations/screen_collections/clearCollection.json'

export default class ClearTimeline {
    constructor(options) {
        this.audio = new AudioPlayer();
        this.activeAnimations = {};
        this.enableNextSlide = options.enableNextSlide;
        
        this.staticAudio = options.staticAudio;
        this.dynamicAudio = options.dynamicAudio;
        
        this.collection = generateCollection(collection);
        this.elements = this.collection.elements;
    }

    tearDown() {
        // REVIEW: @sh0shinsha why is this null and the other way of clearing delete?
        // Deleting local variable in strict mode
        Object.values(this.activeAnimations).forEach((timeline) => {
            timeline.pause();
        });
        
        this.audio.killAllAudio();
        this.audio = null;
    }

    pause() {
        Object.values(this.activeAnimations).forEach(timeline => timeline.pause());
        this.audio.pause();
    }

    resume() {
        Object.values(this.activeAnimations).forEach(timeline => timeline.play());
        this.audio.resume();
    }

    animate() {
        this.collection.setAllElements(anime);

        this.startIndependentLoops();

        const timeline = anime.timeline({});
        this.activeAnimations.timeline = timeline;

        timeline
        .add({
            targets: this.elements.topPanel.tag,
            translateY: this.elements.topPanel.setY(25),
            duration: 500,
            easing: 'linear',
            begin: () => { this.audio.playClip(this.staticAudio.clearHit); }
        })
        .add({
            targets: this.elements.bottomPanel.tag,
            translateY: this.elements.bottomPanel.setY(-25),
            duration: 500,
            easing: 'linear',
        }, '-=500')
        .add({
            targets: this.elements.clearIcon.tag,
            opacity: 1,
            duration: 1000,
            easing: 'easeInQuad'
        }, '-=500')
        .add({
            targets: this.elements.clearIcon.tag,
            keyframes: [
                { scale: 1.5 },
                { scale: 1 },
                { scale: 1.5 },
                { scale: 1 },
                { scale: 1.5 },
                { scale: 1 },
                { scale: 1.5 },
                { scale: 1 }
            ],
            duration: 1500,
            easing: 'easeOutQuad',
            delay: 500,
            changeBegin: () => { this.audio.playClip(this.dynamicAudio.clearAnnouncer) }
        })
        .add({
            targets: this.elements.panelContainer.tag,
            opacity: 0,
            duration: 2000,
            easing: 'easeOutQuad',
            delay: 1000,
            changeBegin: () => { 
                this.audio.playLoop(this.dynamicAudio.clearMusic) 
            }
        })
        .add({
            targets: this.elements.clearIcon.tag,
            opacity: 0.3,
            duration: 1000,
            easing: 'easeOutQuad',
            complete: () => { 
                delete this.activeAnimations.timeline;
                this.enableNextSlide()
            }
        }, '-=2000')
        // TODO: @sh0shinsha tune this...
        .add({
            targets: this.elements.whiteBackground.tag,
            opacity: 0,
            duration: 2000,
            easing: 'easeOutQuad',
        }, '-=3000')
    }

    startIndependentLoops() {
       const clearIcon = anime({
            targets: this.elements.clearIconContainer.tag,
            rotateZ: 360,
            duration: 1600,
            easing: 'linear',
            loop: true
        });
        this.activeAnimations.clearIcon = clearIcon;
    }
}
