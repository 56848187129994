import React from 'react'
import urljoin from 'url-join'
import FirebaseContext from 'auth/FirebaseContext';

import RectangleButton from '../../ui_elements/rectangle/RectangleButton'
import Dropdown from 'react-dropdown'

import Location from '../../img/location.png'
import Organization from '../../img/organization.png'
import Email from '../../img/email.png'

import LoadingMessage from '../../misc_components/LoadingMessage';

function UserContainer(props) {
  const deleteButton = props.signedInUser._id === props.user._id || props.signedInUser.permission >= props.user.permission ? '' : <RectangleButton selectCallback={() => props.deleteUser(props.user.auth_uuid)} text={'DELETE'} color={'red'} size={'xs'} />;
  return (
    <div key={props.user._id} className="all-user-container">
      {/* <h2>{props.user.permission}</h2> */}
      <div className="user-subsection">
        <h2>{props.user.name}</h2>
        <h5>{props.user.email}</h5>
        <h5>{props.user.cached_data.favorite_location_name}</h5>
      </div>
      { deleteButton }
    </div>
  )
}

export default class Users extends React.Component {
  static contextType = FirebaseContext;

  constructor(props) {
    super(props);
    this.state = {
        users: null,
        newUser: {
          name: "",
          email: "",
          emailConfirmation: "",
          permission: "",
          permissionText: "",
          favoriteLocationId: "",
          favoriteLocationName: ""
        },
        isNewUserFormOpen: false,
        hasMissingFields: false,
        hasDifferentEmails: false,
        userConflict: false,
        userSuccess: false
    }

    this.deleteUser = this.deleteUser.bind(this);
  }

  async componentDidMount() {
    this.refreshFromDB()
  }

  async refreshFromDB() {
    const response = await this.context.imagarenaApiClient.get(urljoin(process.env.REACT_APP_ROLES_SVC_ENDPOINT, 'users', 'organizations', this.context.rolesData.org_id)); 

    const users = response.data.users;
    this.setState({ users });
  }

  async createUser() {
    for (let value in this.state.newUser) {
      if(!this.state.newUser[value]) {
        this.setState({ hasMissingFields: true });
        return;
      }
    }

    if (this.state.newUser.email !== this.state.newUser.emailConfirmation) {
      this.setState({ hasDifferentEmails: true });
      return;
    }
      
    const data = {
      user: {
        name: this.state.newUser.name,
        email: this.state.newUser.email,
        permission: parseInt(this.state.newUser.permission),
        favorite_location_id: this.state.newUser.favoriteLocationId,
        favorite_location_name: this.state.newUser.favoriteLocationName
      }
    }
  
    try {
      var response = await this.context.imagarenaApiClient.post(urljoin(process.env.REACT_APP_ROLES_SVC_ENDPOINT, 'users'), data);
    } catch(err) {
      if (err.response.status === 409) {
        this.setState({ userConflict: true })
      }
      return 
    }

    this.context.firebase.auth().sendPasswordResetEmail(data.user.email).then(() => {
      this.setState({ userSuccess: true }, () => { this.refreshFromDB(); });
    }).catch(function(error) {
      // An error happened.
    });
  }

  resetNewUserForm() {
    this.setState({ 
      newUser: {
        name: "",
        email: "",
        emailConfirmation: "",
        permission: "",
        permissionText: "",
        favoriteLocationId: "",
        favoriteLocationName: ""
      },
      isNewUserFormOpen: false,
      hasMissingFields: false,
      userConflict: false,
      userSuccess: false
    })
  }

  updateNewUser(field, value) {
    this.setState(prevState => ({
      newUser: {
        ...prevState.newUser,
        [field]: value
      },
      hasMissingFields: false,
      hasDifferentEmails: false
    }));
  }

  async deleteUser(uuid) {
    if (window.confirm(`Are you sure you want to delete this user?  This action cannot be undone.`)) {
			const response = await this.context.imagarenaApiClient.delete(urljoin(process.env.REACT_APP_ROLES_SVC_ENDPOINT, 'users', uuid));
      this.refreshFromDB();
		}
  }

  render() {
    if (!this.state.users) return <LoadingMessage type="default" message="Loading users..." />

    const locationOptions = this.props.locations.map((location) => {
      return {
        value: location._id,
        label: location.name
      }
    });

    const dropdownOptions = {
      0: [
        { value: 1, label: "Admin" },
        { value: 2, label: "Instructor" }
      ],
      1: [
        { value: 2, label: "Instructor" }
      ]
    }

    const allUsers = this.state.users.map((user) => {
      return <UserContainer key={user._id}user={user} deleteUser={this.deleteUser} signedInUser={this.context.rolesData} />
    });

    const addUserButton = (
      <RectangleButton 
        size={'md'} 
        color={'green'} 
        text={'Confirm'} 
        selectCallback={() => this.createUser()}
      />
    )

    const cancelButton = (
      <RectangleButton 
        size={'sm'} 
        color={'blue'} 
        text={'Cancel'} 
        selectCallback={() => this.resetNewUserForm()}
      />
    )

    const createNewUserView = (
      <div>
        { this.state.hasMissingFields ? <div className="form-field-warning">Please complete all of the fields...</div> : '' }
        { this.state.hasDifferentEmails ? <div className="form-field-warning">Email addresses do not match...</div> : '' }

        <h5>Email:</h5>
        <input value={this.state.newUser.email} onChange={(event) => this.updateNewUser('email', event.target.value)} />

        <h5>Confirm Email:</h5>
        <input value={this.state.newUser.emailConfirmation} onChange={(event) => this.updateNewUser('emailConfirmation', event.target.value)} />

        <h5>Name:</h5>
        <input value={this.state.newUser.name} onChange={(event) => this.updateNewUser('name', event.target.value)} />

        <h5>Account Type:</h5>
        <Dropdown options={dropdownOptions[this.context.rolesData.permission]} onChange={(event) => { this.updateNewUser('permission', event.value); this.updateNewUser('permissionText', event.label);}}  value={this.state.newUser.permissionText} placeholder="Select" />

        <h5>Favorite Location:</h5>
        <Dropdown options={locationOptions} onChange={(event) => { this.updateNewUser('favoriteLocationId', event.value); this.updateNewUser('favoriteLocationName', event.label); }}  value={this.state.newUser.favoriteLocationName} placeholder="Select" />
      
        <div className="user-form-buttons">
          { addUserButton }
          { cancelButton }
        </div>
      </div>
    )

    const openUserFormButton = (
      <RectangleButton 
        size={'sm'} 
        color={'green'} 
        text={'+ CREATE NEW USER'} 
        selectCallback={() => this.setState({ isNewUserFormOpen: true })}
      />
    )

    const preServerResponse = (
      <div>
        { this.state.isNewUserFormOpen ? '' : openUserFormButton}
        { this.state.isNewUserFormOpen ? createNewUserView : allUsers }
      </div>
    )

    const serverResponse = (
      <div>
        { this.state.userConflict ? <h3>A user with this email address already exists.</h3> : '' }
        { this.state.userSuccess ? <h3>User created, and verification email sent!</h3> : '' }
        <RectangleButton text={'Continue'} size={'sm'} color={'blue'} selectCallback={() => this.resetNewUserForm()}/>
      </div>
    )

    return (
      
      <div>
        { this.state.userSuccess || this.state.userConflict ? serverResponse : preServerResponse }
      </div>
    )
  }
}