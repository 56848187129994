import urljoin from 'url-join'
import FirebaseContext from 'auth/FirebaseContext';

import React from 'react';

import EditGroup from './EditGroup'
import GroupSelector from './GroupSelector'
import RectangleButton from '../../ui_elements/rectangle/RectangleButton'

import '../gm.css'

import LoadingMessage from '../../misc_components/LoadingMessage';

export default class AllGroups extends React.Component {
  static contextType = FirebaseContext;

  constructor(props) {
    super(props);
    this.state = {
      groups: null,
      newGroup: {name: ""},
      editingGroup: null,
      creatorVisible: false,
      exceededLength: false
    }
  }

  async componentDidMount() {
    this.refreshFromDB()
  }

  async refreshFromDB() {
    const response = await this.context.imagarenaApiClient.get(urljoin(process.env.REACT_APP_GROUP_MANAGER_SVC_ENDPOINT, 'locations/', this.context.rolesData.favorite_location_id, 'groups')); 
    const groups = response.data.groups;
    this.setState({ groups });
  }

  setNewGroupName(event) {
    if (event.target.value.length > 35 ) {
      this.setState({ exceededLength: true });
      event.target.value.slice(0, 35);
      return;
    }

    this.setState({ exceededLength: false });
    this.setState({newGroup: {name: event.target.value}});
  }

  async createGroup() {
    if (!this.state.newGroup.name) {
      alert('Please enter a group name.');
      return;
    }
    const data = {
      group: {
        name: this.state.newGroup.name,
        location_id: this.context.rolesData.favorite_location_id
      }
    }

    const response = await this.context.imagarenaApiClient.post(urljoin(process.env.REACT_APP_GROUP_MANAGER_SVC_ENDPOINT, 'groups/'), data);

    this.setState({ exceededLength: false })
    this.clearNewGroup();
    this.refreshFromDB();
    this.hideCreator();
  }

  selectEditingGroup(editingGroup) {
    if (this.state.creatorVisible) {
      alert('Please finish creating a new group.');
      return;
    }

    this.setState({editingGroup})
  }

  showCreator() {
    if (this.state.editingGroup) {
      alert('Please finish editing before creating a new group.');
      return;
    }

    this.setState({ creatorVisible: true });
  }

  hideCreator() {
    this.setState({ creatorVisible: false, exceededLength: false });
    this.clearNewGroup();
  }

  clearEditingGroup() {
    this.setState({ editingGroup: null, exceededLength: false });
  }

  clearNewGroup() {
    this.setState({ newGroup: {name: ""} }); // clear the buffer
  }

  render () {

    const groupCreator = (
      <div>
        <div className="group-creator">
          <input value={this.state.newGroup.name} onChange={(event) => this.setNewGroupName(event)} />
          <RectangleButton size={'sm'} clickSFX={'positive'} text={'+ ADD GROUP'} color={'green'} selectCallback={() => this.createGroup()}/>
          <RectangleButton size={'sm'} clickSFX={'negative'} text={'CANCEL'} color={'red'} selectCallback={() => this.hideCreator()}/>
        </div>
        { this.state.exceededLength ? <h5 className="character-warning">Group Names are limited to 35 characters</h5> : '' }
      </div>
    );

    if (!this.state.groups) return <LoadingMessage type="default" message="Loading groups..." />

    return (

      <div className="all-group-container">

          { this.state.creatorVisible ? groupCreator : <RectangleButton size={'sm'} color={'green'} text={'+ CREATE NEW GROUP'} selectCallback={this.state.creatorVisible ? () => this.hideCreator() : () => this.showCreator()} /> }

          {this.state.editingGroup ? <EditGroup refresh={() => this.refreshFromDB()} group={this.state.editingGroup} key={this.state.editingGroup.id} clearEditingGroup={() => this.clearEditingGroup()}/> : '' }

          {this.state.groups.map(group => <GroupSelector key={group.id + group.name} groupObject={group} refresh={() => this.refreshFromDB()} edit={() => this.selectEditingGroup(group)}/>)}

      </div>
    )
  }
}
