import React from 'react'
import { Switch } from "react-router-dom";
import ProtectedRoute from './ProtectedRoute';

import Nav from '../misc_components/Nav';
import HomeScreen from '../misc_components/HomeScreen';
import GameLauncher from '../launcher/components/GameLauncher';
import AllGroups from '../gm/components/AllGroups';
import AllPlaythroughs from '../gm/components/AllPlaythroughs';
import PhotoViewer from '../gm/components/PhotoViewer';
import UserProfile from '../user_profile/UserProfile';
import SupportHome from '../support/SupportHome';

export default function RoutesWithNav(props) {
  // break out into components that the spacing indicates, HomeRoutes, GroupManager routes
  return (
    <div>
      <Nav />
      <Switch>
        <ProtectedRoute exact path="/" component={HomeScreen} />
        <ProtectedRoute path="/launcher" component={GameLauncher} />

        <ProtectedRoute path='/groupmanager' component={AllGroups} />
        <ProtectedRoute path='/playthroughs/:groupId' component={AllPlaythroughs} />
        <ProtectedRoute path='/photoviewer/:playthroughId' component={PhotoViewer} />

        <ProtectedRoute path='/user_profile' component={UserProfile} />
        <ProtectedRoute path='/support' component={SupportHome} />
      </Switch>
    </div>
  )
}