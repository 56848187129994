import anime from 'animejs'
import AudioPlayer from '../audio_utils/AudioPlayer'
import generateCollection from '../../../otaku/src/scripts/generateCollection';

import collection from 'animations/screen_collections/outroCollection.json'

export default class OutroTimeline {
    constructor(options) {
        this.dynamicAudio = options.dynamicAudio;
        this.audio = new AudioPlayer();
        this.enableNextSlide = options.enableNextSlide;

        this.voTriggered = false;

        this.collection = generateCollection(collection);
        this.elements = this.collection.elements;
    }

    checkTime(vid) {
        const timecode = vid.currentTime;
        const VO_OFFSET = 4;

        if (!this.voTriggered) {
            if (timecode > VO_OFFSET) {
                this.audio.playClip(this.dynamicAudio.poweredUp);
                this.voTriggered = true;
                setTimeout(() => this.enableNextSlide(), 5000);
            }
        }

        return;
    }

    tearDown() {
        this.audio.killAllAudio();
        this.audio = null;
    }
    
    pause(vid) {
        this.audio.pauseAllClips();
        if (!this.vidPlaybackComplete) vid.pause();
    }

    resume(vid) {
        this.audio.resumeAllClips();
        if (!this.vidPlaybackComplete) vid.play();
    }

    animate(vid) { 
        this.collection.setAllElements(anime);
        vid.onended = () => {
            this.vidPlaybackComplete = true;
        }

        vid.play();
    }
}
