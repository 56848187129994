export default class ScreenElementCollection {
    constructor(elements) {
        this.elements = elements;
    }

    setAllElements(anime) {
        Object.keys(this.elements).forEach((key) => {
            let el = this.elements[key];
            anime.set(el.tag, el.getStartingPosition());
        });
    }

    setElementsByKey(keys, anime) {
        keys.forEach((key) => {
            let el = this.elements[key];
            anime.set(el.tag, el.getStartingPosition());
        })
    }
}
