import React from 'react'

import Slide from './Slide'

import ChallengeAnimation from '../../../../../animations/components/ChallengeAnimation';

export default class Challenge extends Slide {
    constructor(options) {
        super(options);
    }

getComponent(playthrough) {
        return (
            <ChallengeAnimation
                flags={this.flags}
                dynamicImages={this.dynamicImages}
                staticImages={this.staticImages}
                dynamicAudio={this.dynamicAudio}
                staticAudio={this.staticAudio}
            />
        )
    }
}
