import React from 'react';
import anime from 'animejs'

import { Howl } from 'howler'

import RectangleButton from '../../../ui_elements/rectangle/RectangleButton'

import audio from '../../../ui_elements/ui-audio.json'

import Arrow from "img/arrow.png"

export default class NextArrow extends React.Component {
  constructor(props) {
    super(props);
    this.audio = audio;
  }

  componentDidMount() {
    anime.set('#next-arrow', { scale: 0 });

    const howl = new Howl(this.audio.sfx.nextHit);
    howl.play();

    anime({
      targets: '#next-arrow',
      keyframes: [
        { scale: 1.2 },
        { scale: 1 },
      ],
      duration: 500,
      easing: 'easeInSine',
      complete: () => this.loop()
    });
  }

  componentWillUnmount() {
    const howl = new Howl(this.audio.sfx.cancel);
    howl.play();
  }

  loop() {
    anime({
      targets: '#next-arrow',
      loop: true,
      keyframes: [
        { scale: 1.05 },
        { scale: 1 },
      ],
      duration: 750,
      easing: 'linear'
    })
  }

  render() {
    return (
      <div id="next-arrow" onClick={this.props.goNext} className="next-arrow">
        <RectangleButton text={'NEXT'} size={'md'} img={Arrow} color={'blue'}/>
      </div>
    );
  }
}
