import React from 'react'
import Slide from './Slide'
import TimerAnimation from '../../../../../animations/components/TimerAnimation';

export default class Timer extends Slide {
    constructor(options) {
        super(options);
    }

    getComponent() {
        return (
            <TimerAnimation
                flags={this.flags}
                dynamicImages={this.dynamicImages}
                staticImages={this.staticImages}
                dynamicAudio={this.dynamicAudio}
                staticAudio={this.staticAudio}
            />
        )
    }
}
