import anime from 'animejs'
import AudioPlayer from '../audio_utils/AudioPlayer';
import generateCollection from '../../../otaku/src/scripts/generateCollection';

import collection from 'animations/screen_collections/mapCollection.json'

export default class MapTimeline{
    constructor(options) {
        this.audio = new AudioPlayer();
        this.activeAnimations = {};
        this.enableNextSlide = options.enableNextSlide;

        this.staticAudio = options.staticAudio;
        this.dynamicAudio = options.dynamicAudio;

        this.collection = generateCollection(collection);
        this.elements = this.collection.elements;

        this.roundNum = options.roundNum;
        this.isPreview = options.isPreview;

        this.timeouts = [];
    }

    tearDown() {
        Object.values(this.activeAnimations).forEach((timeline) => {
            timeline.pause();
        });
        
        this.audio.killAllAudio();
        this.audio = null;
       
        this.timeouts.forEach(id => clearTimeout(id))
    }

    timelineComplete(timeline) {
        delete this.activeAnimations[timeline]; 
        this.enableNextSlide();      
    }

    animate(vid) {
        this.collection.setAllElements(anime);
        this.isPreview ? this.previewTimeline() : this.postRoundTimeline(vid);
        // CHECK: is there a better way to do this?  I really need to ditch the mp4...
        vid.onended = () => {
            this.vidPlaybackComplete = true;
        }
    }

    pause(vid) {
        Object.values(this.activeAnimations).forEach((timeline) => {
            timeline.pause();
        });
        this.audio.pause();
        if (!this.vidPlaybackComplete) vid.pause();
    }

    resume(vid) {
        Object.values(this.activeAnimations).forEach((timeline) => {
            timeline.play();
        });
        this.audio.resume();
        if (!this.vidPlaybackComplete) vid.play();
    }

    previewTimeline() {
        const previewTimeline = anime.timeline({});
        this.activeAnimations.previewTimeline = previewTimeline;
        
        previewTimeline
        .add({
            targets: this.elements.mapContainer.tag,
            translateY: this.elements.mapContainer.setY(0),
            duration: 300,
            easing: 'easeInOutCubic',
            delay: 1000,
            changeBegin: () => {this.audio.playClip(this.staticAudio.mapSwipe)}
        })
        .add({
            targets: this.elements.mapContainer.tag,
            translateY: this.elements.mapContainer.setY(100),
            easing: 'easeOutQuad',
            duration: 500,
            delay: 5000,
            complete: () => { this.timelineComplete('previewTimeline')}
        });
    }

    postRoundTimeline(vid) {
        const postRoundTimeline = anime.timeline({});
        this.activeAnimations.postRoundTimeline = postRoundTimeline;

        postRoundTimeline
        .add({
            targets: this.elements.mapContainer.tag,
            translateY: this.elements.mapContainer.setY(0),
            duration: 300,
            easing: 'easeInOutCubic',
            delay: 2000,
            begin: () => {
                vid.play();
                this.audio.playClip(this.staticAudio.completeHit);
                const timeoutId = setTimeout(() => {this.audio.playClip(this.dynamicAudio.roundComplete)}, 500);
                this.timeouts.push(timeoutId);
            },
            changeBegin: () => {this.audio.playClip(this.staticAudio.mapSwipe)},
            // HACK: as long as the mp4 has a whitebackground, this needs to be here
            complete: () => { anime.set(this.elements.blackBackground.tag, { opacity: 1 } ) }
        })
        .add({
            targets: this.elements.activeShell.tag,
            opacity: 0,
            duration: 100,
            easing: 'linear',
            delay: 1000,
            changeBegin: () => {this.audio.playClip(this.staticAudio.mapCellUncover)}
        })
        .add({
            targets: this.elements.completeVideo.tag,
            opacity: 0,
            duration: 100,
            easing: 'linear'
        }, '-=1000')
        .add({
            targets: this.elements.activePlay.tag,
            duration: 3000,
            easing: 'easeOutQuad',
            keyframes: [
                {opacity: 1},
                {opacity: 0},
                {opacity: 1},
                {opacity: 0},
                {opacity: 1},
                {opacity: 0}
            ],
            delay: 1000
        })
        .add({
            targets: this.elements.mapContainer.tag,
            translateY: this.elements.mapContainer.setY(100),
            duration: 500,
            easing: 'easeOutQuad',
            changeBegin: () => {
                this.audio.playClip(this.staticAudio.nextRoundTheme);
                setTimeout(() => this.audio.playClip(this.announcerPhrase()), 400); 
                
                this.idleLoop();
                
                delete this.activeAnimations.postRoundTimeline;
                setTimeout(() => this.enableNextSlide(), 3000);
            }
        })
    }

    idleLoop()  {
        const idleLoop = anime({
            targets: this.elements.idleGraphic.tag,
            keyframes: [{ opacity: 1 }, { opacity: 0 }],
            duration: 1000,
            easing: 'easeOutQuad',
            loop: true
        });

        this.activeAnimations.idleLoop = idleLoop;
    }

    // TODO: @sh0shinsha this should be calc'd on the Map class, not here...
    announcerPhrase() {
        if (this.roundNum === 7) return this.dynamicAudio.finalRound;
        if (this.roundNum === 8) return this.dynamicAudio.creations;

        return this.dynamicAudio.nextRound;
    }
}
