import anime from 'animejs'
import AudioPlayer from '../audio_utils/AudioPlayer';
import TimerTrack from '../audio_utils/TimerTrack';
import generateCollection from '../../../otaku/src/scripts/generateCollection';
import collection from 'animations/screen_collections/timerCollection.json';

function fmtMSS(s){return(s-(s%=60))/60+(9<s?':':':0')+s};

const HALFTIME_PADDING = 5;
const END_PADDING = 11;

export default class TimerTimeline {
    constructor(options) {
        this.audio = new AudioPlayer();
        this.activeAnimations = {};
        this.enableNextSlide = options.enableNextSlide;

        this.staticAudio = options.staticAudio;
        this.dynamicAudio = options.dynamicAudio;

        this.collection = generateCollection(collection);
        this.elements = this.collection.elements;
        
        this.phrases = options.phrases;
        this.bpm = options.bpm;
        this.timeLimit = options.timeLimit;
        this.isMiniTimerDisplay = options.isMiniTimerDisplay;
        this.hasPicture = options.hasPicture;

        console.log(this.phrases)

        // new for the minipreload
        this.containsMiniPreload = options.containsMiniPreload;

        this.timertrack = new TimerTrack({ a: this.dynamicAudio.trackA, b: this.dynamicAudio.trackB, hit: this.dynamicAudio.trackhit });

        this.seqIndex = 0;
        this.animationSeq = [
            { "timecode": this.timeLimit / 2 - HALFTIME_PADDING, "action": "halfway" }, 
            { "timecode": this.timeLimit - END_PADDING, "action": "end" }
        ];

        // Clock utils
        this.startClock = options.startClock;
        this.timeElapsed = options.timeElapsed;

        // Registry of setTimeout ids
        this.timeouts = [];
    }

    tearDown() {
        // REVIEW: @sh0shinsha why is this null and the other way of clearing delete?
        // Deleting local variable in strict mode
        Object.values(this.activeAnimations).forEach((timeline) => {
            timeline.pause();
        });
        
        this.audio.killAllAudio();
        this.audio = null;
    
        this.timertrack.stopAll();
        this.timeouts.forEach(id => clearTimeout(id));
    }

    pause() {
        Object.values(this.activeAnimations).forEach((timeline) => {
            timeline.pause();
        });
        this.audio.pause();
        this.timertrack.pause();
    }

    resume() {
        Object.values(this.activeAnimations).forEach((timeline) => {
            timeline.play();
        });
        this.audio.resume();
        this.timertrack.resume();
    }

    animate() {
        this.collection.setAllElements(anime);

        this.introTimeline = anime.timeline({});
        this.activeAnimations.introTimeline = this.introTimeline;

        if (this.containsMiniPreload) this.miniPreload();
        this.intro();

        this.startIndependentLoops();
    }

    startIndependentLoops() {
        const foreground = anime({ // this is copied over from PreloadAnimation.js...what's the best way to import 'general' animations into files
            targets: this.elements.foreground.tag,
            keyframes: [
                { translateX: this.elements.foreground.setX(5) },
                { translateX: this.elements.foreground.setX(-5) }
            ],
            duration: 5000,
            easing: 'linear',
            loop: true
        });
        this.activeAnimations.foreground = foreground;
    }
    
    generateNumberKeyframes(screenElement) {
        return [
            { translateY: this.elements[screenElement].setY(0), opacity: 1, easing: 'easeOutElastic(1, .5)' },
            { translateY: this.elements[screenElement].setY(-100), opacity: 0, easing: 'easeOutQuint' }
        ]
    }

    miniPreload() {
        this.introTimeline
        .add({
            targets: this.elements.roundHeader.tag,
            keyframes: [
                {
                  scale: 1,
                  duration: 1000,
                  easing: 'easeOutElastic(1, .5)',
                  complete: () => { this.audio.playClip(this.dynamicAudio.round, this.dynamicAudio.roundNum) }
                },
                {
                    scale: 0,
                    duration: 200,
                    easing: 'easeOutQuad',
                    delay: 1000
                }
            ]
        })
        .add({
            targets: this.elements.miniPreloadContainer.tag,
            opacity: 0,
            duration: 1000,
            easing: 'linear'
        })
    }

    intro() {

        this.introTimeline
        .add({
            targets: this.elements.timerText.tag,
            translateY: this.elements.timerText.setY(0),
            duration: 650,
            easing: 'easeOutElastic(1, .5)',
            changeBegin: () => {
                this.audio.playClip(this.staticAudio.timerHit);
                const voTimeout1 = setTimeout( () => this.audio.playClip(...this.phrases.beginning), 1000);
                this.timeouts.push(voTimeout1);
            }
        })

        if (this.hasPicture) {
            this.introTimeline.add({
                targets: this.elements.cameraWhite.tag,
                translateX: this.elements.cameraWhite.setX(42),
                translateY: this.elements.cameraWhite.setY(37),
                duration: 100,
                easing: 'easeOutQuad',
                delay: 2500, // to compensate for time limit announcement
                changeBegin: () => { this.audio.playClip(this.staticAudio.cameraHit); }
            })
        }

        this.introTimeline
        .add({
            targets: this.elements.panelContainer.tag,
            opacity: 1,
            duration: 1000,
            easing: 'easeInQuad',
            delay: this.hasPicture ? 1000 : 3000, // sketchy? don't think so...
        })
        .add({
            targets: this.elements.clockOnlyView.tag,
            opacity: 1,
            // what's a better solution to these duration 1 sketchy calls?  is it even sketchy?
            duration: 1
        })

        if (this.isMiniTimerDisplay) {
            this.introTimeline.add({
                targets: this.elements.timerText.tag,
                translateY: this.elements.timerText.setY(35),
                translateX: this.elements.timerText.setX(-35),
                scale: 0.6,
                // what's a better solution to these duration 1 sketchy calls?  is it even sketchy?
                duration: 1
            })
        }


        this.introTimeline
        .add({
            targets: this.elements.number3.tag,
            keyframes: this.generateNumberKeyframes('number3'),
            delay: 500,
            changeBegin: () => { this.audio.playClip(this.dynamicAudio[3]); this.audio.playClip(this.staticAudio.countdownPingEnd); }
        })
        .add({
            targets: this.elements.number2.tag,
            keyframes: this.generateNumberKeyframes('number2'),
            changeBegin: () => { this.audio.playClip(this.dynamicAudio[2]); this.audio.playClip(this.staticAudio.countdownPingEnd); }
        })
        .add({
            targets: this.elements.number1.tag,
            keyframes: this.generateNumberKeyframes('number1'),
            changeBegin: () => { 
                this.audio.playClip(this.dynamicAudio[1]); 
                this.audio.playClip(this.staticAudio.countdownPingEnd); 
            }
        })
        .add({
            targets: this.elements.topPanel.tag,
            translateY: this.elements.topPanel.setY(75),
            delay: 400,
            duration: 1000,
            easing: 'easeOutExpo',
            begin: () => { 
                this.audio.playClip(this.dynamicAudio.imagine); 
            },
            changeBegin: () => {
                this.audio.playClip(this.staticAudio.open)
            }
        })
        .add({
            targets: this.elements.bottomPanel.tag,
            translateY: this.elements.bottomPanel.setY(-75),
            duration: 1000,
            easing: 'easeOutExpo',
            complete: () => {
                this.timertrack.play();
                this.startClock();
                delete this.activeAnimations.introTimeline;
            }
        }, '-=1000')
    }

    halfway() {
        const halfwayTimeline = anime.timeline({});
        this.activeAnimations.halfwayTimeline = halfwayTimeline;

        halfwayTimeline
        .add({
            targets: this.elements.halfwayGroup.tag,
            opacity: 1,
            duration: 100,
            easing: 'linear'
        })
        .add({
            targets: this.elements.halfwayGif.tag,
            keyframes: [
                { scale: 1, duration: 1000 },
                { scale: 0, easing: 'linear', delay: 3000 },
            ],
            begin: () => {
                this.audio.playClip(...this.phrases.halfway);
            }
        }, '-=100')
        .add({
            targets: this.elements.halfwayGroup.tag,
            opacity: 0,
            duration: 1000,
            easing: 'linear',
            complete: () => delete this.activeAnimations.halfwayTimeline
        })
    }

    end() {
        anime.set(this.elements.number3.tag, { translateY: this.elements.number3.setY(this.elements.number3.y)});
        anime.set(this.elements.number2.tag, { translateY: this.elements.number2.setY(this.elements.number2.y)});
        anime.set(this.elements.number1.tag, { translateY: this.elements.number1.setY(this.elements.number1.y)});

        const endTimeline = anime.timeline({ duration: 1000 });
        this.activeAnimations.endTimeline = endTimeline;

        endTimeline
        .add({
            targets: this.elements.timerText.tag,
            opacity: 0,
            duration: 100,
            easing: 'linear'
        })
        .add({
            targets: this.elements.clockOnlyView.tag,
            opacity: 0,
            duration: 100,
            easing: 'linear'
        }, '-=100')
        .add({
            targets: this.elements.number10.tag,
            keyframes: this.generateNumberKeyframes('number10'),
            begin: () => {this.audio.playClip(this.dynamicAudio[10]);}
        }, '-=100')
        .add({
            targets: this.elements.number9.tag,
            keyframes: this.generateNumberKeyframes('number9'),
            begin: () => {this.audio.playClip(this.dynamicAudio[9]);}
        })
        .add({
            targets: this.elements.number8.tag,
            keyframes: this.generateNumberKeyframes('number8'),
            begin: () => {this.audio.playClip(this.dynamicAudio[8]);}
        })
        .add({
            targets: this.elements.number7.tag,
            keyframes: this.generateNumberKeyframes('number7'),
            begin: () => {this.audio.playClip(this.dynamicAudio[7]);}
        })
        .add({
            targets: this.elements.number6.tag,
            keyframes: this.generateNumberKeyframes('number6'),
            begin: () => {this.audio.playClip(this.dynamicAudio[6]);}
        })
        .add({
            targets: this.elements.number5.tag,
            keyframes: this.generateNumberKeyframes('number5'),
            begin: () => {this.audio.playClip(this.dynamicAudio[5]);}
        })
        .add({
            targets: this.elements.number4.tag,
            keyframes: this.generateNumberKeyframes('number4'),
            begin: () => {this.audio.playClip(this.dynamicAudio[4]);}
        })
        .add({
            targets: this.elements.number3.tag,
            keyframes: this.generateNumberKeyframes('number3'),
            changeBegin: () => { this.audio.playClip(this.dynamicAudio[3]); this.audio.playClip(this.staticAudio.countdownPingEnd); }
        })
        .add({
            targets: this.elements.number2.tag,
            keyframes: this.generateNumberKeyframes('number2'),
            changeBegin: () => { this.audio.playClip(this.dynamicAudio[2]); this.audio.playClip(this.staticAudio.countdownPingEnd); }
        })
        .add({
            targets: this.elements.number1.tag,
            keyframes: this.generateNumberKeyframes('number1'),
            changeBegin: () => { this.audio.playClip(this.dynamicAudio[1]); this.audio.playClip(this.staticAudio.countdownPingEnd); }
        })
        .add({
            begin: () => { this.audio.playClip(this.staticAudio.clockHit); }
        })
        .add({
            targets: this.elements.endContainer.tag,
            opacity: 1,
            duration: 1000,
            easing: 'easeInQuad',
            delay: 1000,
            begin: () => {
                // what i really need is an onEnd callback for playClip, to put enableNextSlide in there...
                this.audio.playClip(this.dynamicAudio.share);
                this.cameraAnimate();
                delete this.activeAnimations.endTimeline;
                // CHECK
                setTimeout(() => this.enableNextSlide(), 4000);
            }
        })
    }

    cameraAnimate = () => {
        if (this.hasPicture) {
            anime.set(this.elements.cameraBlack.tag, { opacity: 1 })
            anime({
                targets: this.elements.cameraBlack.tag,
                keyframes: [{ scale: .8 }, { scale: 1 }],
                loop: true
            }, '-=1000')
        }
    }

    displayTime() {
        const currentTime = this.timeElapsed();

        if (isNaN(currentTime)) { // sketchy bug fix ? what is that white flash sometimes too ?
            return fmtMSS(this.timeLimit);
        } else {
            return fmtMSS(parseInt(this.timeLimit - currentTime));
        }
    }

    animateSeq() {
        const currentTime = this.timeElapsed();

        if (this.seqIndex >= this.animationSeq.length) return;

        if (currentTime >= this.animationSeq[this.seqIndex].timecode) {
            let action = this.animationSeq[this.seqIndex].action;
            this[action]();
            this.seqIndex++;
        }
    }

    // TODO: @sh0shinsha convert to sequence array
    controlTrack() {
        const currentTime = this.timeElapsed();
        const beatLength = 60/this.bpm;

        const halfTimePadded = this.timeLimit/2 - HALFTIME_PADDING;
        const timeToCutA = Math.floor(halfTimePadded/beatLength) * beatLength;

        if (!this.halfHasHit && currentTime >= timeToCutA) { this.timertrack.halfHit(); this.halfHasHit = true }

        const bStart = this.timeLimit/2;
        const fullTimePadded = this.timeLimit - END_PADDING;
        const flooredBeatCount = Math.floor((fullTimePadded - bStart)/beatLength)
        const timeToCutB = flooredBeatCount * beatLength + bStart;

        if (!this.timertrack.b.playing() && currentTime >= bStart && currentTime < timeToCutB) this.timertrack.playB();

        if (!this.endHasHit && currentTime >= timeToCutB) { this.timertrack.endHit(); this.endHasHit = true }
    }
}
