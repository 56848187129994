import urljoin from 'url-join'

import React from 'react';
import { Link } from 'react-router-dom'

import FirebaseContext from 'auth/FirebaseContext';

import EditPhoto from './EditPhoto'

import RectangleButton from '../../ui_elements/rectangle/RectangleButton'
import Arrow from "img/arrow.png"
import LoadingMessage from '../../misc_components/LoadingMessage';

function PhotoContainer(props) {
  const deletePhoto = async () => {
    const response = await props.apiClient.delete(urljoin(process.env.REACT_APP_GROUP_MANAGER_SVC_ENDPOINT, 'playthroughs', props.playthroughId, 'photos', props.photo._id));
  }

  const photoInfo = (
    <div className="photo-info">
      <h2>{props.photo.title}</h2>

        <RectangleButton text={'EDIT'} size={'xs'} selectCallback={() => props.edit()} />
        
        <RectangleButton selectCallback={async () => {
            if (window.confirm('Are you sure you want to delete this photo?')) {
              await deletePhoto();
              props.refresh();
            }
          }} text={'DELETE'} clickSFX={'negative'} color={'red'} size={'xs'}/>


      </div>
  )

  return (
    <div className="photo-container">
      {props.editingPhoto === props.photo ? <EditPhoto refresh={props.refresh} photo={props.editingPhoto} key={props.editingPhoto.id} playthroughId={props.playthroughId}/> : photoInfo}
      <img className="photo" src={props.photo.url} />
    </div>
  )
}

export default class PhotoViewer extends React.Component {
  static contextType = FirebaseContext;

  constructor(props) {
    super(props);
    
    this.state = {
      playthrough: null,
      photos: [],
      editingPhoto: null
    }
  }

  async refreshFromDB() {
    const response = await this.context.imagarenaApiClient.get(urljoin(process.env.REACT_APP_GROUP_MANAGER_SVC_ENDPOINT, 'playthroughs', this.props.match.params.playthroughId)); 
    const playthrough = response.data.playthrough;
    this.setState({ playthrough });
    
    this.setState({playthrough});
  }

  async componentDidMount() {
    this.refreshFromDB();
  }

  setPhotoTitle(event) {
    this.setState({editingPhoto: {...this.state.editingPhoto, title: event.target.value}});
  }
  
  render () {

    if (!this.state.playthrough) return <LoadingMessage type="default" message="Loading photos..." />
    
    return (
      <div className="all-group-container">
        <Link to={`/playthroughs/${this.state.playthrough.group_id}`}><RectangleButton text={`Back to ${this.state.playthrough.cached_data.group_name}`} size={'sm'} img={Arrow} reverse={true} flipImg={true}  /></Link>
       
        <div className="info-bar" id="photo-info">
          <div className="info-piece"><span className="blocked-text">Group Name:</span> {this.state.playthrough.cached_data.group_name}</div>
          {/* <div className="info-piece"><span className="blocked-text">Play Date:</span> {this.state.playthrough.startDate}</div> */}
          {/* <div className="info-piece">Level Config: {JSON.stringify(this.state.playthrough.config)}</div> */}
          <div className="info-piece"><span className="blocked-text">Total Photos:</span> {this.state.playthrough.photos.length}</div>
      </div>

      {this.state.playthrough.photos.map(photo => <PhotoContainer 
        key={photo._id} 
        photo={photo} 
        refresh={() => this.refreshFromDB()} 
        edit={() => this.setState({editingPhoto: photo})} 
        editingPhoto={this.state.editingPhoto} 
        playthroughId={this.state.playthrough._id}
        apiClient={this.context.imagarenaApiClient}
      />)}

      </div>
    )
  }
}