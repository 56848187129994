import React from 'react'
import Slide from './Slide'
import OutroAnimation from '../../../../../animations/components/OutroAnimation';

export default class Outro extends Slide {
    constructor(options) {
        super(options);
    }

    getComponent(playthrough) {
        return ( 
            <OutroAnimation
                flags={this.flags}
                dynamicImages={this.dynamicImages}
                staticImages={this.staticImages}
                dynamicAudio={this.dynamicAudio}
                staticAudio={this.staticAudio}
            />
        )
    }
}