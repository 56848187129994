import React from 'react'
import Slide from './Slide'
import MapAnimation from '../../../../../animations/components/MapAnimation';

export default class Map extends Slide {
    constructor(options) {
        super(options);
    }

    getComponent(playthrough) {
        return (
            <MapAnimation
                flags={this.flags}
                dynamicImages={this.dynamicImages}
                staticImages={this.staticImages}
                dynamicAudio={this.dynamicAudio}
                staticAudio={this.staticAudio}
            />
        )
    }
}
