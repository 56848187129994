import React from 'react';

import PreloadTimeline from '../scripts/anime/PreloadTimeline';

export default class PreloadAnimation extends React.Component {
    constructor(props) {
        super(props);
        this.animation = new PreloadTimeline({
            enableNextSlide: this.props.enableNextSlide,
            staticAudio: this.props.staticAudio,
            dynamicAudio: this.props.dynamicAudio,
            
            playerSelect: this.props.flags.playerSelect,
            jumbo: this.props.flags.isJumbo,
            special: this.props.flags.special,
            // TEMP
            colors: this.props.flags.colors,
            types: this.props.flags.types,
            popUp: this.props.dynamicImages.popUp
        });
    }

    componentDidMount() {
        this.animation.animate();
    }

    componentWillUnmount () {
        this.animation.tearDown();
    }

    pause() {
        this.animation.pause();
    }

    resume() {
        this.animation.resume();
    }

    render() {
        const plusSign = <img id="plus-sign" src={this.props.staticImages.plusSign} />

        const background = this.props.dynamicImages.background ? <img id="background" className="child canvas" src={this.props.dynamicImages.background} /> : '';
        
        const roundHeader = <img id="round-number" className="child" src={this.props.dynamicImages.roundNumber}/>;
        
        const jumboHeader = <img id="jumbo-header" className="child" src={this.props.staticImages.jumboHeader} />;

        const types = (
            <div id="types-container" className="parent container">
                <img id="types-icon" className="child" src={this.props.staticImages.typesIcon} />
                <img id="types-number" className="child" src={this.props.dynamicImages.types} />
            </div>
        );

        const colors = (
            <div id="colors-container" className="parent container">
                <img id="colors-icon" className="child" src={this.props.staticImages.colorsIcon} />
                <img id="colors-number" className="child" src={this.props.dynamicImages.colors} />
            </div>
        );

        const typesAndColorsContainer = (
            <div id="types-and-colors-container" className="parent container">
                { types }
                { colors }
            </div>
        );

        const specialIcon = <img id='special-icon' className="child" src={this.props.dynamicImages.specialIcon} />;
        const specialCard = <img id='special-card' className="child" src={this.props.dynamicImages.specialCard} />;

        const specialContainer = (
            <div id="special-container" className="parent container">
                { specialIcon }
                { specialCard }
            </div>
        );

        const comboContainer = (
            <div id="combo-container" className="parent container">
                { specialContainer }
                { typesAndColorsContainer}
            
            </div>
        )

        // another adaptive id, experimental feature
        const playerIcon = <img id={`${this.props.flags.playerSelect}-icon`} className="child" src={this.props.dynamicImages.playerSelect} />;
            
        const box = (
            <div id="box-and-new-icon-container" className="parent container">
                <img id="box" className="child" src={this.props.dynamicImages.box} />
                {/* <img id="new-icon" className="child" src={this.props.staticImages.newIcon} /> */}
            </div>
        );

        const handA = <img id="default-hand-a" className="child" src={this.props.staticImages.defaultHandA} />;
        
        const handB = <img id="default-hand-b" className="child" src={this.props.staticImages.defaultHandB} />;
        
        const crazyHand = <img id="crazy-hand" className="child" src={this.props.dynamicImages.hand} />;

        const boxAndHands = (
            <div id="box-and-hand-area" className="parent container">  
                { box }
                { handA }
                { handB }
                { crazyHand }
            </div>
        );

        const popUpContainer = (
            <div id="pop-up-container" className="parent container">
                <img id="pop-up" className="child" src={this.props.dynamicImages.popUp} />
                <img id="pop-up-frame" className="child" src={this.props.staticImages.popUpFrame} />
            </div>
        )

        const popUp = this.props.dynamicImages.popUp ? popUpContainer : '';
        
        const foreground = this.props.dynamicImages.foreground ? <img id="foreground" className="child" src={this.props.dynamicImages.foreground} /> : '';
        
        

        return (
            <div id="screen" className="parent">

                { background }

                { roundHeader }

                { jumboHeader }

                { playerIcon }

                { boxAndHands }

                { popUp }

                { foreground }

                { plusSign }

                { comboContainer }

                

            </div>
      );
    }
}
