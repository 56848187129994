import React from 'react'
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Login from '../misc_components/Login';
import PhotoUpload from '../photo_upload/PhotoUpload';
import PlayerLoader from '../player/components/PlayerLoader';

import GlobalErrorModal from '../misc_components/GlobalErrorModal'
import RoutesWithNav from './RoutesWithNav';
import ProtectedRoute from './ProtectedRoute';

// 2/5/20 CHECK: why getting blank screen when <RoutesWithoutNav /> component created?

const NoMatchPage = () => {
  return (
    <h3>404 - Not found</h3>
  );
};

export default function SiteRouter(props) {
  return (
    <Router>
      <GlobalErrorModal 
        error={props.error} 
        modalIsOpen={props.error !== null}
        clearError={props.clearError}
      />
      <Switch>
        <Route path="/login" component={Login} />
        <ProtectedRoute path='/player/:playthroughId' component={PlayerLoader} />
        <ProtectedRoute path='/photo_upload/:playthroughId' component={PhotoUpload} />
        <RoutesWithNav /> 
      </ Switch>
    </Router>
  ) 
}