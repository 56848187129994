import urljoin from 'url-join';
import React from 'react';
import FirebaseContext from 'auth/FirebaseContext';
import RectangleButton from '../../ui_elements/rectangle/RectangleButton';
import RandomIcon from '../../img/random.png';
import generateRandomTitle from '../scripts/generateRandomTitle'

export default class EditPhoto extends React.Component {
  static contextType = FirebaseContext;

  constructor(props) {
    super(props);
    this.state = {
      photo: this.props.photo,
      exceededLength: false
    };

    this.maximumTitleLength = 40;
  }

  async savePhoto() {
    const data = {
      update: {
        title: this.state.photo.title
      }
    }

    const response = await this.context.imagarenaApiClient.patch(urljoin(process.env.REACT_APP_GROUP_MANAGER_SVC_ENDPOINT, 'playthroughs', this.props.playthroughId, 'photos', this.state.photo._id), data);
    this.props.refresh();
  }

  setPhotoTitle(event) {
    if (event.target.value.length > this.maximumTitleLength ) {
      this.setState({ exceededLength: true });
      event.target.value.slice(0, this.maximumTitleLength);
      return;
    }

    this.state.photo.title = event.target.value;
    this.setState({ photo: this.state.photo });
  }

  generateRandomTitle() {
    this.state.photo.title = generateRandomTitle(this.maximumTitleLength)
    this.setState({ photo: this.state.photo });
  }

  render () {
    return (
      <div>
        <div className="photo-edit-container">
        <input onChange={(event) => this.setPhotoTitle(event)} value={this.state.photo.title} />
        <RectangleButton selectCallback={() => this.savePhoto()} clickSFX={'positive'} text={'SAVE'} color={'blue'} size={'xs'} />
        <RectangleButton selectCallback={() => this.generateRandomTitle()} clickSFX={'positive'} text={'RANDOM'} img={RandomIcon} color={'green'} size={'xs'} reverse={true} />
        <RectangleButton selectCallback={() => this.props.refresh()} clickSFX={'negative'} text={'CANCEL'} size={'xs'} />
      </div>
      { this.state.exceededLength ? <h5 className="character-warning photo-warning">Titles are limited to {this.maximumTitleLength} characters</h5> : '' }
      </div>
      
    )
  }
}
