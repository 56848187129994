import anime from 'animejs'
import AudioPlayer from '../audio_utils/AudioPlayer'
import generateCollection from '../../../otaku/src/scripts/generateCollection';

import collection from 'animations/screen_collections/modePreviewCollection.json';

export default class ModePreviewAnimation {
    constructor(options) {
        this.audio = new AudioPlayer();
        this.activeAnimations = {};
        this.enableNextSlide = options.enableNextSlide;

        this.staticAudio = options.staticAudio;

        this.collection = generateCollection(collection);
        this.elements = this.collection.elements;
    }

    tearDown() {
        this.audio.killAllAudio();
        this.audio = null;

        Object.values(this.activeAnimations).forEach((timeline) => {
            timeline.pause();
        });
    }

    pause() {
        Object.values(this.activeAnimations).forEach((timeline) => {
            timeline.pause();
        });
        this.audio.pause();
    }

    resume() {
        Object.values(this.activeAnimations).forEach((timeline) => {
            timeline.play();
        });
        this.audio.resume();
    }

    animate() { 
        this.collection.setAllElements(anime);

        const timeline = anime.timeline({});
        this.activeAnimations.timeline = timeline;

        timeline
        .add({
            targets: this.elements.modeLogo.tag,
            scale: 1,
            changeBegin: () => this.audio.playClip(this.staticAudio.modeHit)
        })
        .add({
            targets: this.elements.modeIcon.tag,
            scale: 1,
            changeBegin: () => this.audio.playClip(this.staticAudio.modeTheme)
        }, '-=500')
        .add({
            targets: this.elements.modeGif.tag,
            opacity: 1,
            duration: 2000,
            easing: 'linear',
            complete: () => { 
                delete this.activeAnimations.timeline;
                this.enableNextSlide(); 
            }
        })
    } 
}
