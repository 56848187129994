import urljoin from 'url-join'

import React from 'react';
import { Link } from 'react-router-dom'

import FirebaseContext from 'auth/FirebaseContext';

import PlaythroughSelector from './PlaythroughSelector'

import RectangleButton from '../../ui_elements/rectangle/RectangleButton'
import Arrow from "img/arrow.png"
import LoadingMessage from '../../misc_components/LoadingMessage';

export default class Allplaythroughs extends React.Component {
    static contextType = FirebaseContext; 

    constructor(props) {
      super(props);

      this.state = {
        playthroughs: null
      }
    }

    async componentDidMount() {
      const response = await this.context.imagarenaApiClient.get(urljoin(process.env.REACT_APP_GROUP_MANAGER_SVC_ENDPOINT, 'groups', this.props.match.params.groupId, 'playthroughs')); 
      const playthroughs = response.data.playthroughs;
      this.setState({ playthroughs });
      
      this.setState({playthroughs});
    }


    render () {

      if (!this.state.playthroughs) return <LoadingMessage type="default" message="Loading playthroughs..." />

      // const totalPhotoCount = this.state.playthroughs.reduce((total, playthrough) => total + playthrough.photoCount, 0);
      const playthroughSelectors = this.state.playthroughs.map(playthrough => <PlaythroughSelector key={playthrough._id} playthrough={playthrough} />);

      return (
        <div className="all-group-container">

            <Link to={'/groupmanager'}><RectangleButton text={'Back to All Groups'} size={'sm'} img={Arrow} reverse={true} flipImg={true} /></Link>


                {/* <h1>{this.state.group.name}</h1> */}
                <div className="group-info-header">
                  {/* <div className="info-piece"><span className="blocked-text">Date Created:</span> { this.state.group.createdDate }</div> */}
                  <div className="info-piece"><span className="blocked-text">Total Playthroughs:</span> { this.state.playthroughs.length }</div>
                  {/* <div className="info-piece"><span className="blocked-text">Total Photos:</span> { totalPhotoCount }</div> */}
                </div>
            {playthroughSelectors}

        </div>
      )
    }
  }
