import ScreenElement from './ScreenElement'

export default class Container extends ScreenElement {
    constructor(options) {
        super(options);
    }

    setX(x) {
        return `${x}%`;
    } 

    setY(y) {
        return `${-y}%`; // added the '-' negative to flip the y coordinates.  why was y-negative 'up' in the first place?
    } 

    getStartingPosition() {
        return {
            translateX: this.setX(this.x),
            translateY: this.setY(this.y),
            scale: this.scale,
            opacity: this.opacity,
            rotateZ: this.rotation
        }
    }
}