import React from 'react';
import urljoin from 'url-join'

import FirebaseContext from 'auth/FirebaseContext';

import BackArrow from './subcomponents/BackArrow';
import NextArrow from './subcomponents/NextArrow';
import ReactTooltip from 'react-tooltip';
import RectangleButton from '../../ui_elements/rectangle/RectangleButton';

import LoadingMessage from '../../misc_components/LoadingMessage';

import Info from 'img/info.png';
import WarningIcon from 'img/exclamation.png';
import SetPlaceholder from 'launcher/img/set_placeholder.png';
import SupplementPlaceholder from 'launcher/img/supplement_placeholder.png';
import NoUpgrades from 'launcher/img/no_upgrades.png';
import NoSupplements from 'launcher/img/no_supplements.png';
import None from 'launcher/img/none.png'

const MATERIAL_SVC = process.env.REACT_APP_MATERIAL_SVC_ENDPOINT;

export default class SelectMaterials extends React.Component {
  static contextType = FirebaseContext;

  constructor(props) {
    super(props);
    this.state = {
      sets: null,
      upgrades: null,
      items: null,
      shapes: null,
      isShowingSupplements: false,
      materialsLoaded: false
    }

    this.dummySupplement = {
      inventory_doc: {
        _id: '',
      },
      metadata: {
        display_info: {
          name: 'None',
          graphic: NoSupplements
        }
      }
    }

    this.dummyUpgrade = {
      inventory_doc: {
        _id: '',
      },
      metadata: {
        display_info: {
          name: 'None',
          graphic: NoUpgrades
        }
      }
    }
  }

  async componentDidMount() {
    const data = await this.fetchData();
    this.setState({ sets: data.sets, upgrades: data.upgrades, items: data.items, shapes: data.shapes }, () => {this.setState({ materialsLoaded: true })});
  }

  async fetchData() {
    const setPromise = this.context.imagarenaApiClient.get(urljoin(MATERIAL_SVC, 'sets/organization', this.context.rolesData.org_id));
    const upgradePromise = this.context.imagarenaApiClient.get(urljoin(MATERIAL_SVC, 'upgrades/organization', this.context.rolesData.org_id));
    const itemPromise = this.context.imagarenaApiClient.get(urljoin(MATERIAL_SVC, 'items/organization', this.context.rolesData.org_id));
    const shapePromise = this.context.imagarenaApiClient.get(urljoin(MATERIAL_SVC, 'shapes/organization', this.context.rolesData.org_id)); 
    const responses = await Promise.all([setPromise, upgradePromise, itemPromise, shapePromise]);
    const [setResponse, upgradeResponse, itemResponse, shapeResponse] = responses;

    return {
      sets: setResponse.data.material_sets,
      upgrades: upgradeResponse.data.upgrades,
      items: itemResponse.data.items,
      shapes: shapeResponse.data.shapes
    }
  }

  selectSupplement(category, data) {
  if (this.props.selectedMaterials.items && this.props.selectedMaterials.shapes) {
    if (this.props.selectedMaterials.items.inventory_doc._id === this.dummySupplement.inventory_doc._id || this.props.selectedMaterials.shapes.inventory_doc._id === this.dummySupplement.inventory_doc._id) {
      this.initSupplements();
    }
  }
    this.props.selectMaterial(category, data);
  }

  initSupplements() {
    this.props.selectMaterial('items', null);
    this.props.selectMaterial('shapes', null);
  }

  render() {
    if (!this.state.materialsLoaded) return <LoadingMessage message="Loading materials..." />

    const tipText = "Choose the Material Set, Upgrade & Supplements you have prepared for this Level."

    const setButtons = this.state.sets.map((set, i) => {
      return <RectangleButton
        key={i}
        img={set.metadata.display_info.icon}
        size={'sm'}
        selected={this.props.selectedMaterials.set ? this.props.selectedMaterials.set.inventory_doc._id === set.inventory_doc._id : false}
        selectCallback={() => this.props.selectMaterial('set', set)}
      />
    });

    const upgradeButtons = this.state.upgrades.map((upgrade, i) => {
      return (
        <div key={i} className="upgrade-button-wrapper">
          <RectangleButton
            key={i}
            img={upgrade.metadata.display_info.icon}
            size={'sm'}
            selected={this.props.selectedMaterials.upgrade ? this.props.selectedMaterials.upgrade.inventory_doc._id === upgrade.inventory_doc._id : false}
            selectCallback={() => this.props.selectMaterial('upgrade', upgrade)}
          />
          {/* <div className={`new-badge ${!this.props.cachedUpgradeIds.includes(upgrade.inventory_doc._id) ? '' : 'hidden'}`}>NEW</div> */}
        </div>
      )
    });

    const itemButtons = this.state.items.map((item, i) => {
      return (
      <div key={i} className="supplement-button-wrapper">
        <RectangleButton
          img={item.metadata.display_info.icon}
          size={'sm'}
          selected={this.props.selectedMaterials.items ? this.props.selectedMaterials.items.inventory_doc._id === item.inventory_doc._id : false}
          selectCallback={() => this.selectSupplement('items', item)}
        />
        <div className={`new-badge ${!this.props.cachedItemIds.includes(item.inventory_doc._id) ? '' : 'hidden'}`}>NEW</div>
      </div>
      )
    });

    const shapeButtons = this.state.shapes.map((shape, i) => {
      return (
        <div key={i} className="supplement-button-wrapper">
          <RectangleButton
            img={shape.metadata.display_info.icon}
            size={'sm'}
            selected={this.props.selectedMaterials.shapes ? this.props.selectedMaterials.shapes.inventory_doc._id === shape.inventory_doc._id : false}
            selectCallback={() => this.selectSupplement('shapes', shape)}
          />
          <div className={`new-badge ${!this.props.cachedShapeIds.includes(shape.inventory_doc._id) ? '' : 'hidden'}`}>NEW</div>
        </div>
      )
    });

    const noSupplementButton = (
      <RectangleButton
          size={'sm'}
          color={'gray'}
          img={None}
          selected={this.props.selectedMaterials.shapes && this.props.selectedMaterials.items ? this.props.selectedMaterials.shapes.inventory_doc._id === this.dummySupplement.inventory_doc._id && this.props.selectedMaterials.items.inventory_doc._id === this.dummySupplement.inventory_doc._id : false}
          selectCallback={() => {
            this.selectSupplement('items', this.dummySupplement);
            this.selectSupplement('shapes', this.dummySupplement);
          }}
      />
    );

    const noUpgradeButton = (
      <RectangleButton
          size={'sm'}
          color={'gray'}
          img={None}
          selected={this.props.selectedMaterials.upgrade ? this.props.selectedMaterials.upgrade.inventory_doc._id === this.dummyUpgrade.inventory_doc._id : false}
          selectCallback={() => this.props.selectMaterial('upgrade', this.dummyUpgrade)}
      />
    );

    const header = (
      <div className="title-section">
        <ReactTooltip id="tip" className="tip">{tipText}</ReactTooltip>
        <div className="title">Select Materials</div>
        <img src={Info} data-tip data-for="tip"/>
      </div>
    );

    const warning = (
      <div id="material-warning">
        <img src={WarningIcon}/>
        <p style={{color: "red"}}>Make sure the graphics below correspond to the materials you've prepared for this session.</p>
      </div>
    );

    const hint = (
      <div id="hint-sentence">
        <p>After selecting the <strong>Material Set</strong>, <strong>Upgrade</strong>, and <strong>Supplements</strong> you have prepared for this session, you'll be able to move on to the next step in the Launcher.</p>
      </div>
    );

    const graphicReview = (
      <div id="material-review-section">
        <img src={this.props.selectedMaterials.set ? this.props.selectedMaterials.set.metadata.display_info.graphic : SetPlaceholder }/>
        <h1>+</h1>
        <img src={this.props.selectedMaterials.upgrade ? this.props.selectedMaterials.upgrade.metadata.display_info.graphic : SetPlaceholder }/>
        <h1>+</h1>
        <div id="item-shape-review-section">
          <img src={this.props.selectedMaterials.items ? this.props.selectedMaterials.items.metadata.display_info.graphic : SupplementPlaceholder }/>
          <img src={this.props.selectedMaterials.shapes ? this.props.selectedMaterials.shapes.metadata.display_info.graphic : SupplementPlaceholder }/>
        </div>
      </div>
    );

    const setOptions = (
      <div className="material-container-launcher material-set">
        <h1>Material Set</h1>
        { setButtons }
      </div>
    );

    const upgradeOptions = (
       <div className="material-container-launcher">
       <h1>Upgrade</h1>
       { noUpgradeButton }
       <h3 style={{margin: "35px"}}>- OR -</h3>
       { upgradeButtons }
     </div>
    );

    const itemShapeOptions = (
      <div className="supplement-container">
        <div className="supplement-column">
          <h3>Items</h3>
          { itemButtons }
        </div>
        <div className="supplement-column">
          <h3>Shapes</h3>
          { shapeButtons }
        </div>
      </div>
    );

    const supplementOptions = (
      <div className="material-container-launcher supplement-packs">
        <h1>Supplements</h1>
        { noSupplementButton }
        <h3 style={{margin: "35px"}}>- OR -</h3>
        { itemShapeOptions }
      </div>
    );

    return (
      <div className="launcher-container">
        <BackArrow goBack={this.props.goBack} />

        { header }

        {/* { warning } */}
        { hint }

        { graphicReview }

        <div className="materials-supplements">
          { setOptions }
          { upgradeOptions }
          { supplementOptions }
        </div>
        
        {this.props.valid ? <NextArrow goNext={this.props.goNext} /> : ''}
      </div>
    )
  }
}
