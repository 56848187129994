import React from 'react'
import './css/loading_message.css'

import Loading from '../img/loading.gif'
import Circle from '../img/loading_circle.gif'

const iconTypes = {
  default: Loading,
  circle: Circle
}

export default function LoadingMessage(props) {
  return (
    <div className={!props.nested ? 'centered-loading-container' : ''} >
      <div className="loading-message-box">
        <img src={props.type ? iconTypes[props.type] : iconTypes['default']} />
        <h4>{props.message}</h4>
      </div>
    </div>  
  )
}