import ScreenElement from './ScreenElement'

export default class Canvas extends ScreenElement {
    constructor(options) {
        super(options);
    }

    getStartingPosition() {
        return {
            opacity: this.opacity
        }
    }
}