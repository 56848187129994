import React from 'react'
import logger from '../../utils/logger'

export default class RectangleButton extends React.Component {
    constructor(props) {
        super(props);
        if (props.onClick != undefined) {
          logger.warn("Instantiating RectangleButton with an onClick is not supported - use prop selectCallback instead")
        }
    }

    aggregateRectClassName() {
      const size = this.props.size;
      const selected =  this.props.selected ? 'selected' : '';
      const color = this.props.color ? this.props.color : '';
      const reverse = this.props.reverse ? 'reverse' : '';
      return `rectangle ${size} ${color} ${selected} ${reverse}`;
    }

    handleClick() {
        if (this.props.selectCallback) {
            this.props.selectCallback();
        }
    }

    render() {
        return (
          <div>
            <div
                className={this.aggregateRectClassName()}
                onClick={ () => this.handleClick() }
            >
                { this.props.text }
                { this.props.img ? <img className={`button-img ${this.props.flipImg ? 'flipped-img' : ''}`} src={this.props.img} /> : '' }
            </div>
          </div>
        )
    }
}
