import React from 'react'
import ModePreviewTimeline from '../scripts/anime/ModePreviewTimeline';

export default class ModePreview extends React.Component {
    constructor(props) {
        super(props);

        this.animation = new ModePreviewTimeline({
            enableNextSlide: this.props.enableNextSlide,
            staticAudio: this.props.staticAudio
        })
    }

    componentDidMount() {
        this.animation.animate();
    }

    componentWillUnmount() {
        this.animation.tearDown();
    }

    pause() {
        this.animation.pause();
    }

    resume() {
        this.animation.resume();
    }

    render() {
        
        

        const modeLogo = <img id="mode-logo" className="child" src={this.props.dynamicImages.modeLogo} />;

        const modeGif = <img id="mode-gif" className="child" src={this.props.dynamicImages.modeGif} />;

        const modeIcon = <img id="mode-icon" className="child" src={this.props.staticImages.modeIcon} />;

        return (
            <div id="screen" className="parent">

                { modeLogo }

                { modeGif }

                { modeIcon }

                

            </div>
        )
    }
}