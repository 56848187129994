import urljoin from 'url-join'
import React from 'react'

import Slide from './Slide'
import ClearAnimation from '../../../../../animations/components/ClearAnimation';
import shuffleArray from '../../../../../utils/shuffleArray';
import loadImagePromise from '../../../../../utils/loadImagePromise';

import randomChoiceFromArray from '../../../../../utils/randomChoiceFromArray';

import PhotosA from './json/clear_default_photos_a.json'
import PhotosB from './json/clear_default_photos_b.json'
import PhotosC from './json/clear_default_photos_c.json'
import PhotosD from './json/clear_default_photos_d.json'
import PhotosE from './json/clear_default_photos_e.json'
import PhotosF from './json/clear_default_photos_f.json'
import PhotosG from './json/clear_default_photos_g.json'
import PhotosH from './json/clear_default_photos_h.json'

export default class Clear extends Slide {
  constructor(options) {
    super(options);
  }
  
  getDefaultPhotos(usedIds) {
    const allCollections = [PhotosA, PhotosB, PhotosC, PhotosD, PhotosE, PhotosF, PhotosG, PhotosH];
    const eligbleCollections = [];
    
    allCollections.forEach((collection) => {
      if (!usedIds.includes(collection.id)) eligbleCollections.push(collection);
    });

    if (eligbleCollections.length) {
      const chosenCollection = randomChoiceFromArray(eligbleCollections);
      usedIds.push(chosenCollection.id);
      return chosenCollection;
    }

    return randomChoiceFromArray(allCollections);
  }

  setDefaultSlideshowData(usedDefaultPhotoIds) {
    const defaultCollection = this.getDefaultPhotos(usedDefaultPhotoIds);
      this.slideshowData = {
        // 1/3/20 CHECK: these photos should really be coming from a seperate endpoint from an svc, not within the client...
        photos: defaultCollection.photos,
        group: {name: "ImagArena Photo Hall of Fame"},
        photoPlaythrough: { start: "2019-1-1" }
      }
  }

  async loadAssets(playthrough, usedPlaythroughIds, usedDefaultPhotoIds, httpClient) {
    await super.loadAssets(playthrough, usedPlaythroughIds, usedDefaultPhotoIds, httpClient, true);

    const query = {
      playthrough_id: playthrough,
      // 1/28/20 CHECK: https://github.com/axios/axios/issues/1139
      external: this.flags.isExternal ? this.flags.isExternal : false,
      playthrough_id_blacklist: usedPlaythroughIds
    }

  
    try {
      var response = await httpClient.get(
        urljoin(process.env.REACT_APP_GROUP_MANAGER_SVC_ENDPOINT, 'playthroughs/random'), 
        { params: { q: query }, globalErrorDisabled: true }
      ); 
      const selectedPlaythrough = response.data;
      
      this.slideshowData = {
        photos: selectedPlaythrough.photos,
        group: { name: selectedPlaythrough.cached_data.group_name },
        photoPlaythrough: { start: selectedPlaythrough.time }
      }

      usedPlaythroughIds.push(selectedPlaythrough._id)
    } catch(err) {
      console.info(err.response.data);
      this.setDefaultSlideshowData(usedDefaultPhotoIds)
    }

    shuffleArray(this.slideshowData.photos);

    try {
      await this.loadSlideshowPhotos();
      this.loaded = true;
    } catch(err) {
      this.setDefaultSlideshowData(usedDefaultPhotoIds);
      await this.loadSlideshowPhotos();
      console.error(err);
      console.error('org_photos cannot be found.  this is most likely from a playthrough document referencing a nonexistent bucket url.')
    }
  }

  async loadSlideshowPhotos() {
    await Promise.all(this.slideshowData.photos.map((photo) => loadImagePromise(photo.url)));
  }

  getComponent() {
      return (
          <ClearAnimation
              flags={this.flags}
              dynamicImages={this.dynamicImages}
              staticImages={this.staticImages}
              dynamicAudio={this.dynamicAudio}
              staticAudio={this.staticAudio}

              slideshowData={this.slideshowData}
          />
      )
  }
}
