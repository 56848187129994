import ImageChild from '../animation_classes/ImageChild'
import DivChild from '../animation_classes/DivChild'
import TextChild from '../animation_classes/TextChild'
import Container from '../animation_classes/Container'
import Canvas from '../animation_classes/Canvas'
import ScreenElementCollection from '../animation_classes/ScreenElementCollection'

export default function generateCollection(collectionJSON) {
    let screenElements = {};
    Object.keys(collectionJSON).forEach((key) => {
        switch (collectionJSON[key].type) {
            case 'img':
                screenElements[key] = new ImageChild(collectionJSON[key]); // is it mad glad that 'type' is passed into class, even though unused?
                break;
            case 'container': 
                screenElements[key] = new Container(collectionJSON[key]);
                break;
            case 'text': 
                screenElements[key] = new TextChild(collectionJSON[key]);
                break;
            case 'div':
                screenElements[key] = new DivChild(collectionJSON[key]);
                break;
            case 'canvas':
                screenElements[key] = new Canvas(collectionJSON[key]);
                break;
            default:
                throw Error(`Unknown screen element type '${collectionJSON[key].type}'`);
        } 
    });

    return new ScreenElementCollection(screenElements);  
}
